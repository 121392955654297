const TEXT = {
  HOME_PAGE: {
    WELCOME_MESSAGE: 'homePage.welcomeMessage',
    MANAGE_CUSTOMERS: 'homePage.manageCustomers',
    VIEW_CUSTOMERS: 'homePage.viewCustomers',
    VIEW_ASSETS: 'homePage.viewAssets',
    PENDING_DEALER_RECOMMENDATIONS: 'homePage.pendingDealerRecommendations',
    VIEW_ASSET_REQUESTS: 'homePage.viewAssetRequests',
    ASSET_RECOMMENDATIONS_LINK_TEXT: 'homePage.assetRecommendationsLinkText',
    ASSET_RECOMMENDATIONS_TEXT: 'homePage.assetRecommendationsText',
    CUSTOMER_RECOMMENDATIONS_LINK_TEXT: 'homePage.customerRecommendationsLinkText',
    DEALER_RECOMMENDATIONS_LINK_TEXT: 'homePage.dealerRecommendationsLinkText',
    DEALER_RECOMMENDATIONS_TEXT: 'homePage.dealerRecommendationsText',
    TASKS_TO_COMPLETE_TEXT: 'homePage.tasksToCompleteText',
  },
  MY_CUSTOMERS: {
    COLUMNS: {
      CUSTOMER: 'myCustomers.columns.customer',
      CUSTOMER_ID: 'myCustomers.columns.customerId',
      INDICATOR: 'myCustomers.columns.indicator',
    },
    TYPE: {
      DEALER_CUSTOMER: 'myCustomers.type.dealerCustomer',
    },
    CREATE_DEALER_CUSTOMER: {
      VALIDATION: {
        DUPLICATE_DCN: 'myCustomers.createDealerCustomer.validation.duplicateDCN',
        INVALID_COUNTRY_CODE: 'myCustomers.createDealerCustomer.validation.invalidCountryCode',
        INVALID_STATE_CODE: 'myCustomers.createDealerCustomer.validation.invalidStateCode',
      },
      DEALER_CUSTOMER_NAME: 'myCustomers.createDealerCustomer.dealerCustomerName',
      DEALER_CUSTOMER_ID: 'myCustomers.createDealerCustomer.dealerCustomerId',
    },
    SEARCH_DEALER_CUSTOMER: {
      SEARCH_FOR_DEALER_CUSTOMER: 'myCustomers.searchDealerCustomer.searchForDealerCustomer',
    },
    INFO_TAGS: {
      NO_DEALER_CUSTOMERS: 'myCustomers.infoTags.noDealerCustomers',
    },
    SEARCH_NAME_ID: 'myCustomers.searchNameId',
    SEARCH_FOR_CUSTOMER_ID: 'myCustomers.searchForCustomerId',
    ERRORS: {
      SEARCH_ASSOCIATED_ORGANIZATIONS_COUNT:
        'myCustomers.errors.searchAssociatedOrganizationsCount',
      SEARCH_ASSOCIATED_CUSTOMERS: 'myCustomers.errors.searchAssociatedCustomers',
      SEARCH_ASSOCIATED_DEALER_CUSTOMER_COUNT:
        'myCustomers.errors.searchAssociatedDealerCustomerCount',
      CUSTOMER_ORGANISATION_FETCH: 'myCustomers.errors.customerOrganisationFetch',
      DEALER_CUSTOMERS_ID_FETCH: 'myCustomers.errors.dealerCustomersIDFetch',
      SEARCH_BY_CUSTOMER_ORGANIZATION: 'myCustomers.errors.searchByCustomerOrganization',
      CUSTOMER_ORGANIZATIONS_BY_IDENTIFIER: 'myCustomers.errors.customerOrganizationsByIdentifier',
      SEARCH_MANAGED_CUSTOMERS: 'myCustomers.errors.searchManagedCustomers',
      CONSOLIDATE_ORGANIZATIONS: 'myCustomers.errors.consolidateOrganizations',
      CUSTOMER_DELETE: 'myCustomers.errors.customerDelete',
      CUSTOMER_CHANGES_SAVING: 'myCustomers.errors.customerChangesSaving',
      GETTING_ASSOCIATED: 'myCustomers.errors.gettingAssociated',
      TOTAL_COUNT_OF_REC_FAILED: 'myCustomers.errors.totalCountOfRecFailed',
      CUSTOMER_INFO_REFRESH: 'myCustomers.errors.customerInfoRefresh',
      SEARCH_CUSTOMER_ORGANIZATIONS: 'myCustomers.errors.searchCustomerOrganizations',
      DUPLICATE_CUSTOMER: 'myCustomers.errors.duplicateCustomer',
      RETRIEVE_LOGS: 'myCustomers.errors.retrieveLogs',
      RETRIEVE_DETAILS_LOGS: 'myCustomers.errors.retrieveDetailsLogs',
      ROLES_BY_PARTY_FETCH: 'myCustomers.errors.rolesByPartyFetch',
      NOT_AUTHORIZED_TO_ACTION: 'myCustomers.errors.notAuthorizedToAction',
      NOT_ASSOCIATED_BY_ORGANIZATION: 'myCustomers.errors.notAssociatedByOrganization',
      DISASSOCIATE_USER: 'myCustomers.errors.disassociateUser',
    },
    NOTIFICATIONS: {
      SET_ORGANIZATIONS_STATUS: 'myCustomers.notifications.setOrganizationsStatus',
      CUSTOMER_ORGANIZATION_SUBMIT: 'myCustomers.notifications.customerOrganizationSubmit',
      CUSTOMER_OBSOLETE: 'myCustomers.notifications.customerObsolete',
      CUSTOMER_INFORMATION_UPDATE: 'myCustomers.notifications.customerInformationUpdate',
      CUSTOMER_ASSOCIATIONS_APPLY: 'myCustomers.notifications.customerAssociationsApply',
      CUSTOMER_ACTIVATION: 'myCustomers.notifications.customerActivation',
      CUSTOMER_CREATE: 'myCustomers.notifications.customerCreate',
      CONSOLIDATION_REQUEST_RECEIVE: 'myCustomers.notifications.consolidationRequestReceive',
      ASSOCIATION_SUCCESS: 'myCustomers.notifications.associationSuccess',
      ASSOCIATION_REMOVED: 'myCustomers.notifications.associationRemoved',
    },
    ACCOUNT_TYPES: {
      MANAGED: 'myCustomers.accountTypes.managed',
      MANAGED_EXPLAINED: 'myCustomers.accountTypes.managedExplained',
      TOP_LEVEL: 'myCustomers.accountTypes.topLevel',
      TOP_LEVEL_EXPLAINED: 'myCustomers.accountTypes.topLevelExplained',
      ACTIVE: 'myCustomers.accountTypes.active',
      ACTIVE_EXPLAINED: 'myCustomers.accountTypes.activeExplained',
      INACTIVE: 'myCustomers.accountTypes.inactive',
      INACTIVE_EXPLAINED: 'myCustomers.accountTypes.inactiveExplained',
      OBSOLETE: 'myCustomers.accountTypes.obsolete',
      OBSOLETE_EXPLAINED: 'myCustomers.accountTypes.obsoleteExplained',
      PROSPECT: 'myCustomers.accountTypes.prospect',
      PROSPECT_EXPLAINED: 'myCustomers.accountTypes.prospectExplained',
    },
    NO_DATA: 'myCustomers.noData',
    ADD_CUSTOMER_FOOTER: 'myCustomers.addCustomerFooter',
    ADD_CUSTOMER: {
      TITLE: 'myCustomers.addCustomer.title',
      CHECK_FOR_DUPLICATES: {
        NO_DUPLICATES_LABEL_TEXT:
          'myCustomers.addCustomer.checkForDuplicates.noDuplicatesLabelText',
        NO_DUPLICATES_LABEL_CONTINUE:
          'myCustomers.addCustomer.checkForDuplicates.noDuplicatesLabelContinue',
        YOUR_ENTRY_RETURNED: 'myCustomers.addCustomer.checkForDuplicates.yourEntryReturned',
        MATCHES: 'myCustomers.addCustomer.checkForDuplicates.matches',
      },
      REVIEW_RESULTS_LABEL: 'myCustomers.addCustomer.reviewResultsLabel',
      CUSTOMER_ADDRESS: 'myCustomers.addCustomer.customerAddress',
    },
    EDIT_CUSTOMER: {
      TITLE: 'myCustomers.editCustomer.title',
      MODAL_WARNING_OBSOLETE: 'myCustomers.editCustomer.modalWarningObsolete',
    },
    ENTER_CUSTOMER_INFO: {
      MANAGED_ACCOUNT_CODE: 'myCustomers.enterCustomerInfo.managedAccountCode',
      INLINE_MANAGED_ACCOUNT_CODE: 'myCustomers.enterCustomerInfo.inlineManagedAccountCode',
      MODAL_WARNING_OBSOLETE: 'myCustomers.enterCustomerInfo.modalWarningObsolete',
    },
    ASSOCIATE_CUSTOMER: {
      TITLE: 'myCustomers.associateCustomer.title',
      MODAL_WARNING_OBSOLETE: 'myCustomers.associateCustomer.modalWarningObsolete',
      MODAL_WARNING_LOCKED: 'myCustomers.associateCustomer.modalWarningLocked',
    },
    ASSOCIATE_DEALER_CUSTOMER: {
      TITLE: 'myCustomers.associateDealerCustomer.title',
      NOTIFICATIONS: {
        NEW_DEALER_CUSTOMER_CREATE:
          'myCustomers.associateDealerCustomer.notifications.newDealerCustomerCreate',
        CUSTOMER_ASSOCIATION_REMOVE:
          'myCustomers.associateDealerCustomer.notifications.customerAssociationRemove',
        DEALER_CUSTOMER_UPDATE:
          'myCustomers.associateDealerCustomer.notifications.dealerCustomerUpdate',
        ASSOCIATIONS_COUNT: 'myCustomers.associateDealerCustomer.notifications.associationsCount',
      },
      ERRORS: {
        SEARCH_DEALER_CUSTOMER: 'myCustomers.associateDealerCustomer.errors.searchDealerCustomer',
        RETRIEVING_DEALER_DATA: 'myCustomers.associateDealerCustomer.errors.retrievingDealerData',
        SEARCH_PRIMARY_CUSTOMER_ADDRESS:
          'myCustomers.associateDealerCustomer.errors.searchPrimaryCustomerAddress',
        NEW_DEALER_CUSTOMER: 'myCustomers.associateDealerCustomer.errors.newDealerCustomer',
        DEALER_CODE_NOT_FOUND: 'myCustomers.associateDealerCustomer.errors.dealerCodeNotFound',
        ASSOCIATIONS_FAILED: 'myCustomers.associateDealerCustomer.errors.associationsFailed',
        COMBINATION_EXISTS: 'myCustomers.associateDealerCustomer.errors.combinationExists',
      },
      WARNINGS: {
        PROSPECT_CUSTOMER_ORGANIZATION:
          'myCustomers.associateDealerCustomer.warnings.prospectCustomerOrganization',
        NO_DEALERS_FOUND: 'myCustomers.associateDealerCustomer.warnings.noDealersFound',
        HAS_ASSETS: 'myCustomers.associateDealerCustomer.warnings.hasAssets',
        LOCKED_DUE_CONSOLIDATION:
          'myCustomers.associateDealerCustomer.warnings.lockedDueConsolidation',
        DCN_ASSOCIATED_TO: 'myCustomers.associateDealerCustomer.warnings.DCNAssociatedTo',
      },
      APPLY_ASSOCIATIONS: 'myCustomers.associateDealerCustomer.applyAssociations',
      DEALER_CUSTOMER_LOOKUP: 'myCustomers.associateDealerCustomer.dealerCustomerLookup',
      CUSTOMER_ASSOCIATION: 'myCustomers.associateDealerCustomer.customerAssociation',
      NO_ASSOCIATION: 'myCustomers.associateDealerCustomer.noAssociation',
      LOADING_MORE_RECORDS: 'myCustomers.associateDealerCustomer.loadingMoreRecords',
      ADD: 'myCustomers.associateDealerCustomer.add',
      NO_MATCHING_FOUND: 'myCustomers.associateDealerCustomer.noMatchingFound',
      CREATE_NEW_DCN: 'myCustomers.associateDealerCustomer.createNewDcn',
    },
    DISASSOCIATE_DEALER_CUSTOMER: {
      TITLE: 'myCustomers.disassociateDealerCustomer.title',
      TITLE_PLURAL: 'myCustomers.disassociateDealerCustomer.titlePlural',
      MODAL_WARNING: 'myCustomers.disassociateDealerCustomer.modalWarning',
      DISASSOCIATE_WARNING_TITLE: 'myCustomers.disassociateDealerCustomer.disassociateWarningTitle',
      SUBSCRIPTION_WARNING: 'myCustomers.disassociateDealerCustomer.subscriptionWarning',
      SUBSCRIPTION_WARNING_PLURAL:
        'myCustomers.disassociateDealerCustomer.subscriptionWarningPlural',
      FAILED_DISASSOCIATIONS_COUNT:
        'myCustomers.disassociateDealerCustomer.failedDisassociationsCount',
      SUCCESS_DISASSOCIATIONS_COUNT:
        'myCustomers.disassociateDealerCustomer.successDisassociationsCount',
      DISASSOCIATIONS: 'myCustomers.disassociateDealerCustomer.disassociations',
      APPLY_DISASSOCIATIONS: 'myCustomers.disassociateDealerCustomer.applyDisassociations',
      WARNINGS: {
        CONSOLIDATION_LOCK: 'myCustomers.disassociateDealerCustomer.warnings.consolidationLock',
        DRAFT: 'myCustomers.disassociateDealerCustomer.warnings.draft',
        SUBSCRIPTIONS: 'myCustomers.disassociateDealerCustomer.warnings.subscriptions',
      },
    },
    ASSOCIATE_USER: {
      TITLE: 'myCustomers.associateUser.title',
      EDIT_USER_TITLE: 'myCustomers.associateUser.editUserTitle',
      USER_LOOK_UP: 'myCustomers.associateUser.userLookUp',
      USER_NAME: 'myCustomers.associateUser.userName',
      USER_EMAIL: 'myCustomers.associateUser.userEmail',
      NO_MATCHING_USERS: 'myCustomers.associateUser.noMatchingUsers',
      WARNINGS: {
        PROSPECT: 'myCustomers.associateUser.warnings.prospect',
        USER_ASSOCIATED_TO_CUSTOMER_ID:
          'myCustomers.associateUser.warnings.userAssociatedToCustomerID',
        INVALID_IDENTITY_TYPE: 'myCustomers.associateUser.warnings.invalidIdentityType',
        NO_RESULTS_FOUND: 'myCustomers.associateUser.warnings.noResultsFound',
      },
      REMOVE_USER_ASSOCIATION: 'myCustomers.associateUser.removeUserAssociation',
      REMOVE_CONFIRMATION: 'myCustomers.associateUser.removeConfirmation',
      REMOVE_TIME: 'myCustomers.associateUser.removeTime',
    },
    CONSOLIDATE: {
      TITLE: 'myCustomers.consolidate.title',
      CONSOLIDATE_CUSTOMERS: 'myCustomers.consolidate.consolidateCustomers',
      DRAFT_PROMPT: 'myCustomers.consolidate.draftPrompt',
      DRAFT_ACTIVATE: 'myCustomers.consolidate.draftActivate',
      DRAFT_NOTE: 'myCustomers.consolidate.draftNote',
      MODAL_WARNING_OBSOLETE: 'myCustomers.consolidate.modalWarningObsolete',
      MODAL_WARNING_LOCKED: 'myCustomers.consolidate.modalWarningLocked',
      MODAL_CONFIRM: 'myCustomers.consolidate.modalConfirm',
      CONSOLIDATE_ONE_TO_TWO: 'myCustomers.consolidate.consolidateOneToTwo',
      SELECT_CONSOLIDATE_TO: 'myCustomers.consolidate.selectConsolidateTo',
      ASSET_COUNT_DISCLAIMER: 'myCustomers.consolidate.assetCountDisclaimer',
      ASSET_COUNT: 'myCustomers.consolidate.assetCount',
      FROM_CUSTOMER: 'myCustomers.consolidate.fromCustomer',
      TO_CUSTOMER: 'myCustomers.consolidate.toCustomer',
      DEALER_CUSTOMER_COUNT: 'myCustomers.consolidate.dealerCustomerCount',
      USER_COUNT: 'myCustomers.consolidate.userCount',
      CUSTOMER_COUNT: 'myCustomers.consolidate.customerCount',
      VALIDATIONS: {
        DUPLICATE_ID: 'myCustomers.consolidate.validations.duplicateId',
        FULL_ORG_ID: 'myCustomers.consolidate.validations.fullOrgId',
        OBSOLETE_STATUS: 'myCustomers.consolidate.validations.obsoleteStatus',
      },
    },
    ROW_DETAILS: {
      CUSTOMER_ORGANIZATIONS: 'myCustomers.rowDetails.customerOrganizations',
      DEALER_CUSTOMERS: 'myCustomers.rowDetails.dealerCustomers',
      HISTORY: 'myCustomers.rowDetails.history',
      SELECT_HISTORY: 'myCustomers.rowDetails.selectHistory',
      EDIT_DEALER_CUSTOMER: 'myCustomers.rowDetails.editDealerCustomer',
      ERRORS: {
        NO_ASSOCIATED_ORG: 'myCustomers.rowDetails.errors.noAssociatedOrg',
        NO_DEALER_CUSTOMER: 'myCustomers.rowDetails.errors.noDealerCustomer',
        NO_USERS: 'myCustomers.rowDetails.errors.noUsers',
        NO_USERS_FOUND: 'myCustomers.rowDetails.errors.noUsersFound',
        NO_HISTORY: 'myCustomers.rowDetails.errors.noHistory',
        CUSTOMER_ORD_NOT_FOUND: 'myCustomers.rowDetails.errors.customerOrdNotFound',
      },
    },
    VIEW_ASSOCIATIONS: 'myCustomers.viewAssociations',
    VIEW_DISASSOCIATIONS: 'myCustomers.viewDisassociations',
    AUDIT_LOG: {
      UPDATED: 'myCustomers.auditLog.updated',
      CONSOLIDATED: 'myCustomers.auditLog.consolidated',
      CREATED: 'myCustomers.auditLog.created',
      CHANGED: 'myCustomers.auditLog.changed',
      RECOMMENDATION_UPDATED: 'myCustomers.auditLog.recommendationUpdated',
    },
    ADVANCED_SEARCH_FIELD: {
      CUSTOMER_ORG_CITY: 'myCustomers.advancedSearchField.customerOrgCity',
      CUSTOMER_ORG_ID: 'myCustomers.advancedSearchField.customerOrgId',
      CUSTOMER_ORG_NAME: 'myCustomers.advancedSearchField.customerOrgName',
      CUSTOMER_ORG_POSTAL_CODE: 'myCustomers.advancedSearchField.customerOrgPostalCode',
      DEALER_CUSTOMER_NAME: 'myCustomers.advancedSearchField.DealerCustomerName',
      DEALER_CUSTOMER_NUMBER: 'myCustomers.advancedSearchField.DealerCustomerNumber',
      CUSTOMER_ORG_MANAGED_ACCOUNT_CODE:
        'myCustomers.advancedSearchField.customerOrgManagedAccountCode',
      DEALER_CUSTOMER_MANAGED_ACCOUNT_CODE:
        'myCustomers.advancedSearchField.dealerCustomerManagedAccountCode',
    },
    ADVANCED_SEARCH_MATCH_RULE_OPTIONS: {
      CONTAINS: 'myCustomers.advancedSearchMatchRuleOptions.contains',
      FUZZY: 'myCustomers.advancedSearchMatchRuleOptions.fuzzy',
      EQUALS: 'myCustomers.advancedSearchMatchRuleOptions.equals',
      BEGINS_WITH: 'myCustomers.advancedSearchMatchRuleOptions.beginsWith',
      ENDS_WITH: 'myCustomers.advancedSearchMatchRuleOptions.endsWith',
    },
    CUSTOMER_DEALER_SEARCH_NAME_ID: 'myCustomers.customerDealerSearchNameId',
    CUSTOMER_INFORMATION: 'myCustomers.customerInformation',
    ASSOCIATED_DEALER_CUSTOMERS: 'myCustomers.associatedDealerCustomers',
    ASSOCIATED_USERS: 'myCustomers.associatedUsers',
    ASSOCIATED_CUSTOMERS: 'myCustomers.associatedCustomers',
    BLANK_STATE: {
      NO_DEALER_ASSOCIATIONS: 'myCustomers.blankState.noDealerAssociations',
      NO_ASSOCAITIONS_EXIST: 'myCustomers.blankState.noAssocaitionsExist',
      NO_CUSTOMER_ASSOCIATIONS: 'myCustomers.blankState.noCustomerAssociations',
      NO_USER_ASSOCIATIONS: 'myCustomers.blankState.noUserAssociations',
    },
    CONSOLIDATION_CONFIRMATION: 'myCustomers.consolidationConfirmation',
    CUSTOMER_PROFILE: 'myCustomers.customerProfile',
    EXISTING_ASSOCIATIONS: 'myCustomers.existingAssociations',
    ASSOCIATIONS_NOT_APPLIED: 'myCustomers.associationsNotApplied',
    SUCCESSFUL_ASSOCIATIONS: 'myCustomers.successfulAssociations',
    FAILED_ASSOCIATIONS: 'myCustomers.failedAssociations',
    CUSTOMER_LOOKUP: 'myCustomers.customerLookup',
  },
  ACCESS_REQUESTS: {
    VALIDATION_SCHEMA: {
      ADD_COMPANY_REQUIRED: 'accessRequests.validationSchema.addCompanyRequired',
      ADD_DEALER_CUSTOMERS_REQUIRED: 'accessRequests.validationSchema.addDealerCustomersRequired',
      ADD_COMPANY_REQUIRED_TO_SELECT_DEALER_CUSTOMERS:
        'accessRequests.validationSchema.addCompanyRequiredToSelectDealerCustomers',
      SELECT_ROLE_REQUIRED: 'accessRequests.validationSchema.selectRoleRequired',
      ADD_COMPANY_REQUIRED_TO_SELECT_ROLE:
        'accessRequests.validationSchema.addCompanyRequiredToSelectRole',
    },
    LABELS: {
      USER: 'accessRequests.labels.user',
      CUSTOMER_ASSOCIATION: 'accessRequests.labels.customerAssociation',
      REQUEST_PENDING: 'accessRequests.labels.requestPending',
      USERNAME: 'accessRequests.labels.username',
      ID: 'accessRequests.labels.id',
      DAYS: 'accessRequests.labels.days',
    },
    STATUS: {
      APPROVED: 'accessRequests.status.approved',
      APPROVED_EXPLAINED: 'accessRequests.status.approvedExplained',
      DENIED: 'accessRequests.status.denied',
      DENIED_EXPLAINED: 'accessRequests.status.deniedExplained',
      FAILED: 'accessRequests.status.failed',
      FAILED_EXPLAINED: 'accessRequests.status.failedExplained',
      PENDING: 'accessRequests.status.pending',
      PENDING_EXPLAINED: 'accessRequests.status.pendingExplained',
      PROCESSING: 'accessRequests.status.processing',
      PROCESSING_EXPLAINED: 'accessRequests.status.processingExplained',
    },
    ROW_DETAILS: {
      DATE_REQUESTED: 'accessRequests.rowDetails.dateRequested',
      USERNAME: 'accessRequests.rowDetails.username',
      PHONE: 'accessRequests.rowDetails.phone',
      REGISTRATION_FORM_INPUT: 'accessRequests.rowDetails.registrationFormInput',
      USER_ENTRY: 'accessRequests.rowDetails.userEntry',
      EXACT_MATCH: 'accessRequests.rowDetails.exactMatch',
      SELECTED_MATCH: 'accessRequests.rowDetails.selectedMatch',
      PROVIDED_SERIAL_NUMBERS: 'accessRequests.rowDetails.providedSerialNumbers',
      DCN: 'accessRequests.rowDetails.dcn',
    },
    DETAILS_CARD: {
      ACCESS_APPROVAL_DETAILS: 'accessRequests.detailsCard.accessApprovalDetails',
      COMPANY: 'accessRequests.detailsCard.company',
      APPLICATION_NAME: 'accessRequests.detailsCard.applicationName',
      VIEW_DEALER_CUSTOMERS_TITLE: 'accessRequests.detailsCard.viewDealerCustomersTitle',
      VIEW_DEALER_CUSTOMERS: 'accessRequests.detailsCard.viewDealerCustomers',
      EDIT_DEALER_CUSTOMERS: 'accessRequests.detailsCard.editDealerCustomers',
      ADD_DEALER_CUSTOMERS: 'accessRequests.detailsCard.addDealerCustomers',
      ACTIONED_BY: 'accessRequests.detailsCard.actionedBy',
      DATE_APPROVED: 'accessRequests.detailsCard.dateApproved',
      DATE_DENIED: 'accessRequests.detailsCard.dateDenied',
      SELECT_ONE: 'accessRequests.detailsCard.selectOne',
      SELECT_ROLE: 'accessRequests.detailsCard.selectRole',
      ADD_COMPANY: 'accessRequests.detailsCard.addCompany',
      CHANGE_COMPANY: 'accessRequests.detailsCard.changeCompany',
      COMMENTS: 'accessRequests.detailsCard.comments',
      GET_ROLES_ERROR: {
        TITLE: 'accessRequests.detailsCard.getRolesError.title',
      },
      RESTRICTED_ACCOUNT_WARNING_MESSAGE:
        'accessRequests.detailsCard.restrictedAccountWarningMessage',
    },
    WARNING: 'accessRequests.warning',
    SELECT_FILTERS: 'accessRequests.selectFilters',
    NO_RESULTS_FOUND: 'accessRequests.noResultsFound',
    REVISING_SEARCH_CRITERIA: 'accessRequests.revisingSearchCriteria',
    NO_ACCESS_REQUESTS_EXIST: 'accessRequests.noAccessRequestsExist',
  },
  INVITATIONS: {
    NOTIFICATIONS: {
      RESEND_INVITATION: 'invitations.notifications.resendInvitation',
      REVOKE_INVITATION: 'invitations.notifications.revokeInvitation',
      REQUEST_PROCESSED_PARTIAL: 'invitations.notifications.requestProcessedPartial',
      REQUEST_PROCESSED: 'invitations.notifications.requestProcessed',
      UNDO_REVOKE: 'invitations.notifications.undoRevoke',
      INVITE_UPDATE: 'invitations.notifications.inviteUpdate',
    },
    ERRORS: {
      FETCH_INVITE: 'invitations.errors.fetchInvite',
      FETCH_INVITE_COUNT: 'invitations.errors.fetchInviteCount',
      FETCH_CUSTOMER: 'invitations.errors.fetchCustomer',
      FETCH_DEALER: 'invitations.errors.fetchDealer',
      RESEND_INVITATION: 'invitations.errors.resendInvitation',
      REVOKE_INVITATION: 'invitations.errors.revokeInvitation',
      REQUEST_PROCESSED: 'invitations.errors.requestProcessed',
      UNDO_REVOKE: 'invitations.errors.undoRevoke',
      UPDATE: 'invitations.errors.update',
    },
    REVOKE_MODAL: {
      TITLE: 'invitations.revokeModal.title',
      CONTENT: 'invitations.revokeModal.content',
    },
    INVITATION_SENDING: {
      RESEND_INVITATIONS: 'invitations.invitationSending.resendInvitations',
      NO_INVITATIONS_EXIST: 'invitations.invitationSending.noInvitationsExist',
      SELECT_DEADER_CODE_TO_INVITATION:
        'invitations.invitationSending.selectDeaderCodeToInvitation',
      RESEND_INVITATION_CONTINUE: 'invitations.invitationSending.resendInvitationContinue',
      ONLY_PENDING_AND_ACCEPTED_DISPLAYED:
        'invitations.invitationSending.onlyPendingAndAcceptedDisplayed',
      SELECT_ACCOUNT: 'invitations.invitationSending.selectAccount',
      USER_WITH_CWSID_AUTOMATICALLY: 'invitations.invitationSending.userWithCWSIDAutomatically',
    },
    TITLE: {
      INVITE_USER: 'invitations.title.inviteUser',
      USER_INFORMATION: 'invitations.title.userInformation',
      APPLICATION_ACCESS: 'invitations.title.applicationAccess',
    },
    INVITATIONS_DRAWER: {
      USER_WORK_CUSTOMER: 'invitations.invitationsDrawer.userWorkCustomer',
      INVITATION_LANGUAGE: 'invitations.invitationsDrawer.invitationLanguage',
      INVITE_SETTINGS: 'invitations.invitationsDrawer.inviteSettings',
      HISTORY_LOG: 'invitations.invitationsDrawer.historyLog',
      LAST_ACTIONED_BY: 'invitations.invitationsDrawer.lastActionedBy',
      EDIT_INVITE: 'invitations.invitationsDrawer.editInvite',
      VIEW_INVITE: 'invitations.invitationsDrawer.viewInvite',
    },
    STATUS_DESCRIPTION: {
      PENDING_STATUS: 'invitations.statusDescription.pendingStatus',
      ACCEPTED_STATUS: 'invitations.statusDescription.acceptedStatus',
      REVOKED_STATUS: 'invitations.statusDescription.revokedStatus',
    },
    COLUMN_INVITATIONS: {
      COPY_INVITE_LINK: 'invitations.columnInvitations.copyInviteLink',
      RESEND_INVITE: 'invitations.columnInvitations.resendInvite',
      UNDO_REVOKE: 'invitations.columnInvitations.undoRevoke',
      VIEW_INVITE: 'invitations.columnInvitations.viewInvite',
      EDIT_INVITE: 'invitations.columnInvitations.editInvite',
      REVOKE_INVITE: 'invitations.columnInvitations.revokeInvite',
      EDIT_INVITE_AND_UNDO_REVOKE: 'invitations.columnInvitations.EditInviteAndUndoRevoke',
      MENU: 'invitations.columnInvitations.menu',
    },
    STEPS: {
      SELECT_A_CUSTOMER: 'invitations.steps.selectACustomer',
      ADD_USER_EMAILS: 'invitations.steps.addUserEmails',
      SELECT_APPLICATION_ACCESS: 'invitations.steps.selectApplicationAccess',
    },
    ADD_USER_EMAILS: {
      ENTER_EMAILS: 'invitations.addUserEmails.enterEmails',
      MAX_EMAILS: 'invitations.addUserEmails.maxEmails',
      DISCLAIMER: 'invitations.addUserEmails.disclaimer',
      WORK_FOR_ABOVE: 'invitations.addUserEmails.workForAbove',
      WORK_FOR_ABOVE_ASSOCIATED: 'invitations.addUserEmails.workForAboveAssociated',
      INVITE_LANGUAGE: 'invitations.addUserEmails.inviteLanguage',
    },
    SELECT_APPLICATION_ACCESS: {
      INVITE_USER: 'invitations.selectApplicationAccess.inviteUser',
      CREATE_INVITATION_DISCLAIMER:
        'invitations.selectApplicationAccess.createInvitationDisclaimer',
      CREATE_INVITE_LINK: 'invitations.selectApplicationAccess.createInviteLink',
      CONFIRM_INVITE: 'invitations.selectApplicationAccess.confirmInvite',
      CHOOSE_APPLICATION: 'invitations.selectApplicationAccess.chooseApplication',
      APPLICATION_DESCRIPTION_PCC: 'invitations.selectApplicationAccess.applicationDescriptionPCC',
      APPLICATION_DESCRIPTION_FM3: 'invitations.selectApplicationAccess.applicationDescriptionFM3',
    },
    REVIEW: {
      SENT_INVITES: 'invitations.review.sentInvites',
      FAILURE: 'invitations.review.failure',
      NO_INVITES_SENT: 'invitations.review.noInvitesSent',
      INVITATION_LINKS: 'invitations.review.invitationLinks',
      COPY_LINK: 'invitations.review.copyLink',
      SUCCESS_APPS_INVITE: 'invitations.review.successAppsInvite',
      FAIL_APP_INVITE: 'invitations.review.failAppInvite',
    },
    SELECT_DEALER_CUSTOMER: 'invitations.selectDealerCustomer',
    RESTRICTED_ACCOUNT_WARNING_MESSAGE: 'invitations.restrictedAccountWarningMessage',
  },
  ASSET_RECOMMENDATION: {
    COLUMNS: {
      WORK_ITEM_GROUP_ID: 'assetRecommendation.columns.workItemGroupID',
      INITIATE_DATE: 'assetRecommendation.columns.initiateDate',
      CONFIDENCE_RATING: 'assetRecommendation.columns.confidenceRating',
      REASON: 'assetRecommendation.columns.reason',
      OWNERSHIP_TYPE: 'assetRecommendation.columns.ownershipType',
    },
    ASSET_OWNERSHIP_CHANGE: {
      TITLE: 'assetRecommendation.assetOwnershipChange.title',
      INFO: {
        WE_RECOMMEND_CHANGE: 'assetRecommendation.assetOwnershipChange.info.weRecommendChange',
        ASSET_OWNER_CORRECT: 'assetRecommendation.assetOwnershipChange.info.assetOwnerCorrect',
        ACCEPTED_RECOMMENDATIONS_CREATE:
          'assetRecommendation.assetOwnershipChange.info.acceptedRecommendationsCreate',
      },
      REASONS: {
        ANOTHER_CUSTOMER_CONTACTED:
          'assetRecommendation.assetOwnershipChange.reasons.anotherCustomerContacted',
        CUSTOMER_SUGGESTED: 'assetRecommendation.assetOwnershipChange.reasons.customerSuggested',
        DEALER_CUSTOMER_RELATIONSHIP:
          'assetRecommendation.assetOwnershipChange.reasons.dealerCustomerRelationship',
        NO_CONTACT_CUSTOMER: 'assetRecommendation.assetOwnershipChange.reasons.noContactCustomer',
        NO_INFORMATION_ABOUT_ASSET:
          'assetRecommendation.assetOwnershipChange.reasons.noInformationAboutAsset',
        MULTI_TERRITORY_CLIENT:
          'assetRecommendation.assetOwnershipChange.reasons.multiTerritoryClient',
        MANAGED_BY_DEALER: 'assetRecommendation.assetOwnershipChange.reasons.managedByDealer',
        OTHER: 'assetRecommendation.assetOwnershipChange.reasons.other',
      },
    },
    ERRORS: {
      NO_ASSET_RECOMMENDATION: 'assetRecommendation.errors.noAssetRecommendation',
    },
  },
  CUSTOMER_RECOMMENDATIONS: {
    COLUMNS: {
      WORK_ITEM_TYPE: 'customerRecommendations.columns.workItemType',
      WORK_ITEM_GROUP_ID: 'customerRecommendations.columns.workItemGroupId',
      INITIALIZED_DATE: 'customerRecommendations.columns.initializedDate',
    },
    ITEMS_FEEDBACK: {
      TITLE: {
        DEALER_TO_NEW_CUSTOMER: 'customerRecommendations.itemsFeedback.title.dealerToNewCustomer',
        DEALER_TO_EXISTING_CUSTOMER:
          'customerRecommendations.itemsFeedback.title.dealerToExistingCustomer',
        EXISTING_CUSTOMER_TO_NEW_CUSTOMER:
          'customerRecommendations.itemsFeedback.title.existingCustomerToNewCustomer',
        MULTIPLE_DCN: 'customerRecommendations.itemsFeedback.title.multipleDcn',
      },
      MULTIPLE_DCN_REASON: 'customerRecommendations.itemsFeedback.multipleDcnReason',
      COLUMNS: {
        DEALER_CUSTOMER_COUNT: 'customerRecommendations.itemsFeedback.columns.dealerCustomerCount',
        DEALER_CUSTOMER_ID: 'customerRecommendations.itemsFeedback.columns.dealerCustomerId',
      },
      CONFIDENCE_RATING_TITLE: 'customerRecommendations.itemsFeedback.confidenceRatingTitle',
      CONFIDENCE_RATING_TEXT: 'customerRecommendations.itemsFeedback.confidenceRatingText',
      REASON_TITLE: 'customerRecommendations.itemsFeedback.reasonTitle',
      VALIDATIONS: {
        INVALID_PRIMARY_CUSTOMER:
          'customerRecommendations.itemsFeedback.validations.invalidPrimaryCustomer',
      },
      DISCLAINMER: {
        INVALID_CUSTOMER: 'customerRecommendations.itemsFeedback.disclainmer.invalidCustomer',
        RESOLVE_EDIT: 'customerRecommendations.itemsFeedback.disclainmer.resolveEdit',
      },
      ICONS_EXPLAINED: 'customerRecommendations.itemsFeedback.iconsExplained',
      FEEDBACK_STATUS: 'customerRecommendations.itemsFeedback.feedbackStatus',
      RECOMMENDATION_ITEMS: 'customerRecommendations.itemsFeedback.recommendationItems',
      RECOMMENDATION_GROUP_ID: 'customerRecommendations.itemsFeedback.recommendationGroupId',
      RECOMMENDATION_TYPE: 'customerRecommendations.itemsFeedback.recommendationType',
      SUBHEADER: {
        DEALER_TO_NEW_CUSTOMER:
          'customerRecommendations.itemsFeedback.subheader.dealerToNewCustomer',
        DEALER_TO_EXISTING_CUSTOMER:
          'customerRecommendations.itemsFeedback.subheader.dealerToExistingCustomer',
        EXISTING_CUSTOMER_TO_NEW_CUSTOMER:
          'customerRecommendations.itemsFeedback.subheader.existingCustomerToNewCustomer',
        MULTIPLE_DCN: 'customerRecommendations.itemsFeedback.subheader.multipleDcn',
      },
      FEEDBACK_EXPLAINED: {
        DEALER_TO_NEW_CUSTOMER:
          'customerRecommendations.itemsFeedback.feedbackExplained.dealerToNewCustomer',
        DEALER_TO_EXISTING_CUSTOMER:
          'customerRecommendations.itemsFeedback.feedbackExplained.dealerToExistingCustomer',
        EXISTING_CUSTOMER_TO_NEW_CUSTOMER:
          'customerRecommendations.itemsFeedback.feedbackExplained.existingCustomerToNewCustomer',
        MULTIPLE_DCN: 'customerRecommendations.itemsFeedback.feedbackExplained.multipleDcn',
      },
      FEEDBACK_WARNING: {
        DEALER_TO_NEW_CUSTOMER:
          'customerRecommendations.itemsFeedback.feedbackWarning.dealerToNewCustomer',
      },
      COUNTS: {
        CUSTOMERS: 'customerRecommendations.itemsFeedback.counts.customers',
        DEALER_CUSTOMERS: 'customerRecommendations.itemsFeedback.counts.dealerCustomers',
        USERS: 'customerRecommendations.itemsFeedback.counts.users',
      },
      REVIEW: {
        NO_ASSOCIATIONS_APPLIED:
          'customerRecommendations.itemsFeedback.review.noAssociationsApplied',
        ASSOCIATED_CUSTOMER_ORG:
          'customerRecommendations.itemsFeedback.review.associatedCustomerOrg',
        TITLE: 'customerRecommendations.itemsFeedback.review.title',
      },
      STATUS: {
        APPROVED: 'customerRecommendations.itemsFeedback.status.approved',
        REJECTED: 'customerRecommendations.itemsFeedback.status.rejected',
        UNKNOWN: 'customerRecommendations.itemsFeedback.status.unknown',
      },
      REASON_FOR_REJECTION: 'customerRecommendations.itemsFeedback.reasonForRejection',
      PLEASE_SELECT_REASON: 'customerRecommendations.itemsFeedback.pleaseSelectReason',
      SELECT_A_REASON: 'customerRecommendations.itemsFeedback.selectAReason',
      INSERT_REASON: 'customerRecommendations.itemsFeedback.insertReason',
      REASONS: {
        GROUPING_INVALID: 'customerRecommendations.itemsFeedback.reasons.groupingInvalid',
        CORPORATE_AFFILIATED: 'customerRecommendations.itemsFeedback.reasons.CorporateAffiliated',
        VENTURE: 'customerRecommendations.itemsFeedback.reasons.venture',
        INDEPENDENT_AFFILIATED:
          'customerRecommendations.itemsFeedback.reasons.independentAffiliated',
        COMPLIANCE: 'customerRecommendations.itemsFeedback.reasons.compliance',
      },
      REJECTION_FOR_DCN: 'customerRecommendations.itemsFeedback.rejectionForDCN',
      REJECTION_FOR_CONTACT: 'customerRecommendations.itemsFeedback.rejectionForContact',
      REJECTION_FOR_DEALER_CONTACT:
        'customerRecommendations.itemsFeedback.rejectionForDealerContact',
    },
    ERRORS: {
      FEEDBACK_SAVING: 'customerRecommendations.errors.feedbackSaving',
    },
    TAKE_ACTION: 'customerRecommendations.takeAction',
    EDIT_SUGGESTED_CUSTOMER: 'customerRecommendations.editSuggestedCustomer',
    STILL_CREATE_CUSTOMER: 'customerRecommendations.stillCreateCustomer',
    PRIMARY_CUSTOMER: 'customerRecommendations.primaryCustomer',
    PRIMARY_CUSTOMER_ADDRESS: 'customerRecommendations.primaryCustomerAddress',
    PRIMARY_CUSTOMER_NAME: 'customerRecommendations.primaryCustomerName',
    PRIMARY_CUSTOMER_ID: 'customerRecommendations.primaryCustomerId',
    SUGGESTED_PRIMARY_CUSTOMER: 'customerRecommendations.suggestedPrimaryCustomer',
    CREATE_CUSTOMER: 'customerRecommendations.createCustomer',
  },
  OWNERSHIP_REQUESTS: {
    TAB: {
      RECEIVED: 'ownershipRequests.tab.received',
      SENT: 'ownershipRequests.tab.sent',
    },
    COLUMN: {
      SERIAL_NUMBER: 'ownershipRequests.column.serialNumber',
      MAKE: 'ownershipRequests.column.make',
      NEW_OWNER: 'ownershipRequests.column.newOwner',
      REQUESTED_BY: 'ownershipRequests.column.requestedBy',
      CURRENT_CUSTOMER: 'ownershipRequests.column.currentCustomer',
      CURRENT_DEALER_CUSTOMER: 'ownershipRequests.column.currentDealerCustomer',
      REQUESTING_DEALER: 'ownershipRequests.column.requestingDealer',
      REQUESTED_DATE: 'ownershipRequests.column.requestedDate',
      REQUESTED_TIME: 'ownershipRequests.column.requestedTime',
      NO_REQUESTS: 'ownershipRequests.column.noRequests',
      NEW_DEALER_CUSTOMER: 'ownershipRequests.column.newDealerCustomer',
      NEW_CUSTOMER: 'ownershipRequests.column.newCustomer',
    },
    STATUS_DESCRIPTION: {
      PENDING_STATUS: 'ownershipRequests.statusDescription.pendingStatus',
      APPROVED_STATUS: 'ownershipRequests.statusDescription.approvedStatus',
      DENIED_STATUS: 'ownershipRequests.statusDescription.deniedStatus',
    },
    TITLE: {
      CURRENT_OWNERSHIP_TITLE: 'ownershipRequests.title.currentOwnershipTitle',
      OWNERSHIP_REQUEST_TITLE: 'ownershipRequests.title.ownershipRequestTitle',
      OWNERSHIP_TYPE: 'ownershipRequests.title.ownershipType',
      OWNER_CHANGE_REQUEST: 'ownershipRequests.title.ownerChangeRequest',
      SUBSCRIPTION: 'ownershipRequests.title.subscription',
      SUBSCRIBED: 'ownershipRequests.title.subscribed',
      OWNERSHIP_START_DATE: 'ownershipRequests.title.ownershipStartDate',
      OWNERSHIP_STATUS: 'ownershipRequests.title.ownershipStatus',
      ACTIONED_BY: 'ownershipRequests.title.actionedBy',
      ASSET_OWNER_BY_DIFFERENT_DEALER: 'ownershipRequests.title.assetOwnerByDifferentDealer',
      DEALER_CODE_SELECTION: 'ownershipRequests.title.dealerCodeSelection',
      FIND_DEALER_CUSTOMER: 'ownershipRequests.title.findDealerCustomer',
    },
    PLACEHOLDERS: {
      SEARCH_CUSTOMER_ID: 'ownershipRequests.placeholders.searchCustomerId',
      SEARCH_SERIAL_NUMBER: 'ownershipRequests.placeholders.searchSerialNumber',
    },
    ACTIONS: {
      EDIT_ASSET: 'ownershipRequests.actions.editAsset',
      UPDATE_OWNERSHIP: 'ownershipRequests.actions.updateOwnership',
      ADD_OWNER: 'ownershipRequests.actions.addOwner',
      SEND_REQUEST: 'ownershipRequests.actions.sendRequest',
      VALIDATE_CHANGE_OWNERSHIP: 'ownershipRequests.actions.validateChangeOwnership',
    },
    INFO: {
      ADDRESS_NOT_FOUND: 'ownershipRequests.info.addressNotFound',
      CURRENTLY_OWNERSHIP_RECORD_TIED_ASSET:
        'ownershipRequests.info.currentlyOwnershipRecordTiedAsset',
      SELECT_ASSOCIATED_DEALER_CODE: 'ownershipRequests.info.selectAssociatedDealerCode',
      NO_DEALER_CUSTOMER_ASSOCIATIONS: 'ownershipRequests.info.noDealerCustomerAssociations',
      GET_STARTED_BY_SEARCHING_DEALER: 'ownershipRequests.info.getStartedBySearchingDealer',
      REMINDER_TO_UPDATE_ERP: 'ownershipRequests.info.reminderToUpdateERP',
      EXISTING_OWNERSHIP_NO_CONFLICT: 'ownershipRequests.info.existingOwnershipNoConflict',
      CHANGING_OWNERSHIP_WILL_CANCEL: 'ownershipRequests.info.changingOwnershipWillCancel',
      ARE_YOU_SURE: 'ownershipRequests.info.areYouSure',
      CHANGING_OWNERSHIP_WILL_EXPIRE: 'ownershipRequests.info.changingOwnershipWillExpire',
      PENDING_OWNERSHIP_ALREADY_EXISTS: 'ownershipRequests.info.pendingOwnershipAlreadyExists',
      PENDING_OWNERSHIP_ALREADY_EXISTS_FOR_ASSET:
        'ownershipRequests.info.pendingOwnershipAlreadyExistsForAsset',
    },
    CANCEL_REQUESTS: {
      BUTTON: 'ownershipRequests.cancelRequests.button',
      WARNING_MODAL_TEXT: 'ownershipRequests.cancelRequests.warningModalText',
      WARNING_CONFIRM_BUTTON: 'ownershipRequests.cancelRequests.warningConfirmButton',
    },
    PAST_OWNERSHIP_TYPE: 'ownershipRequests.pastOwnershipType',
  },
  COMMON: {
    TITLE: {
      CUSTOMERS: 'common.title.customers',
      DEALER_CUSTOMERS: 'common.title.dealerCustomers',
      NEEDS_REVIEW: 'common.title.needsReview',
      RECOMMENDATIONS: 'common.title.recommendations',
      WORK_QUEUE: 'common.title.workQueue',
      ACCESS_REQUESTS: 'common.title.accessRequests',
      ASSET_RECOMMENDATIONS: 'common.title.assetRecommendations',
      CUSTOMER_RECOMMENDATIONS: 'common.title.customerRecommendations',
      OWNERSHIP_REQUESTS: 'common.title.ownershipRequests',
      OWNERSHIP_REQUEST: 'common.title.ownershipRequest',
      USERS: 'common.title.users',
      INVITATIONS: 'common.title.invitations',
      ACCESS_MANAGEMENT: 'common.title.accessManagement',
      ASSETS: 'common.title.assets',
      IMPERSONATION: 'common.title.impersonation',
      ADMINS: 'common.title.admins',
      DEALER_CODE: 'common.title.dealerCode',
      ACCOUNT: 'common.title.account',
      NAME: 'common.title.name',
      USERNAME: 'common.title.username',
      EMAIL: 'common.title.email',
      EMAIL_ADDRESS: 'common.title.emailAddress',
      EMAIL_TYPE: 'common.title.emailType',
      CUSTOMER: 'common.title.customer',
      CUSTOMER_NAME: 'common.title.customerName',
      USER: 'common.title.user',
      STATUS: 'common.title.status',
      HOME: 'common.title.home',
      SETTINGS: 'common.title.settings',
      ENTER_CUSTOMER_INFO: 'common.title.enterCustomerInfo',
      CHECK_FOR_DUPLICATES: 'common.title.checkForDuplicates',
      ASSOCIATE_RECOMMENDATIONS: 'common.title.associateRecommendations',
      ASSOCIATE_DEALER_CUSTOMERS: 'common.title.associateDealerCustomers',
      ASSOCIATE_USERS: 'common.title.associateUsers',
      REVIEW_RESULTS: 'common.title.reviewResults',
      ASSOCIATE_CUSTOMERS: 'common.title.associateCustomers',
      NUMBER: 'common.title.number',
      ASSET_HAS_SUBSCRIPTION: 'common.title.assetHasSubscription',
      CCID: 'common.title.ccid',
      DCN: 'common.title.dcn',
      CAT_FI_PRODUCTS: 'common.title.catFiProducts',
    },
    ERRORS: {
      CUSTOMER_SEARCH: 'common.errors.customerSearch',
      CUSTOMER_SEARCH_COUNT: 'common.errors.customerSearchCount',
      FETCH_USERS: 'common.errors.fetchUsers',
      GENERIC: 'common.errors.generic',
      SAVE_CHANGES: 'common.errors.saveChanges',
      LEAVING_PAGE: 'common.errors.leavingPage',
      INFORMATION: 'common.errors.information',
      REQUESTED_INFORMATION: 'common.errors.requestedInformation',
      REQUEST_INFORMATION_TRY_AGAIN: 'common.errors.requestInformationTryAgain',
      TECHNICAL_DIFFICULTY: 'common.errors.technicalDifficulty',
    },
    SORT: {
      A_TO_Z: 'common.sort.aToZ',
      Z_TO_A: 'common.sort.zToA',
      MOST_RECENT: 'common.sort.mostRecent',
      LEAST_RECENT: 'common.sort.leastRecent',
      RELEVANCE: 'common.sort.relevance',
      ASCENDING: 'common.sort.ascending',
      DESCENDING: 'common.sort.descending',
      CREATED_BY: 'common.sort.createdBy',
      DATE_INVITED: 'common.sort.dateInvited',
    },
    FILTERS: {
      TITLES: {
        CUSTOMERS: 'common.filters.titles.customers',
        ACCESS_REQUESTS: 'common.filters.titles.accessRequests',
        INVITATIONS: 'common.filters.titles.invitations',
        USERS: 'common.filters.titles.users',
        OWNERSHIP_REQUESTS: 'common.filters.titles.ownershipRequests',
        RECOMMENDATIONS: 'common.filters.titles.recommendations',
        ASSETS: 'common.filters.titles.assets',
      },
      SECTIONS: {
        CATEGORY: 'common.filters.sections.category',
        DATE: 'common.filters.sections.date',
        PRE_SET_SELECTION: 'common.filters.sections.preSetSelection',
        ACCOUNT_SELECTION: 'common.filters.sections.accountSelection',
        CUSTOMER_SELECTION_MAXIMUM: 'common.filters.sections.customerSelectionMaximum',
        CUSTOMER_SELECTION_MAXIMUM_NO_ASTERISK:
          'common.filters.sections.customerSelectionMaximumNoAsterisk',
        INITIATED_DATE: 'common.filters.sections.initiatedDate',
      },
      ITALIC_LABELS: {
        PRE_SET_SELECTION: 'common.filters.italicLabels.preSetSelection',
        CUSTOM_SELECTION: 'common.filters.italicLabels.customSelection',
        PRE_SELECTED_RANGE: 'common.filters.italicLabels.preSelectedRange',
        CUSTOM_RANGE: 'common.filters.italicLabels.customRange',
        CUSTOMER_SELECTION: 'common.filters.italicLabels.customerSelection',
      },
      INPUT_LABELS: {
        COUNTRY: 'common.filters.inputLabels.country',
        STATE_PROVINCE: 'common.filters.inputLabels.stateProvince',
        DEALER_CODE: 'common.filters.inputLabels.dealerCode',
        CUSTOMER_STATUS: 'common.filters.inputLabels.customerStatus',
        ACCOUNT: 'common.filters.inputLabels.account',
        ACCESS_REQUEST_STATUS: 'common.filters.inputLabels.accessRequestStatus',
        APPLICATION: 'common.filters.inputLabels.application',
        APPLICATIONS: 'common.filters.inputLabels.applications',
        INVITATION_STATUS: 'common.filters.inputLabels.invitationStatus',
        OWNERSHIP_REQUEST_STATUS: 'common.filters.inputLabels.ownershipRequestStatus',
        WORK_ITEM_TYPE: 'common.filters.inputLabels.workItemType',
        OWNERSHIP_TYPE: 'common.filters.inputLabels.ownershipType',
        STATUS: 'common.filters.inputLabels.status',
      },
      BUTTONS_LABELS: {
        ASSOCIATIONS: 'common.filters.buttonsLabels.associations',
        MANAGED_ACCOUNT: 'common.filters.buttonsLabels.managedAccount',
        REQUEST_TYPE: 'common.filters.buttonsLabels.requestType',
        ASSET_TYPE: 'common.filters.buttonsLabels.assetType',
      },
      BUTTON_OPTIONS: {
        HAS_DEALER_CUSTOMERS: 'common.filters.buttonOptions.hasDealerCustomers',
        NO_DEALER_CUSTOMERS: 'common.filters.buttonOptions.noDealerCustomers',
        MANAGED_ACCOUNT: 'common.filters.buttonOptions.managedAccount',
        NOT_MANAGED_ACCOUNT: 'common.filters.buttonOptions.notManagedAccount',
        LAST_SEVEN_DAYS: 'common.filters.buttonOptions.lastSevenDays',
        LAST_MONTH: 'common.filters.buttonOptions.lastMonth',
        LAST_THREE_MONTHS: 'common.filters.buttonOptions.lastThreeMonths',
        LAST_SIX_MONTHS: 'common.filters.buttonOptions.lastSixMonths',
        LAST_YEAR: 'common.filters.buttonOptions.lastYear',
        APPLICATION_ACCESS_REQUEST: 'common.filters.buttonOptions.applicationAccessRequest',
        ASSOCIATION_APPLICATION_ACCESS_REQUEST:
          'common.filters.buttonOptions.associationApplicationAccessRequest',
        SHOW_LESS: 'common.filters.buttonOptions.showLess',
        SHOW_ALL: 'common.filters.buttonOptions.showAll',
        SELECT_DEALER_CODE: 'common.filters.buttonOptions.selectDealerCode',
        SELECT_DEADER_CODE_USER_REGISTERED:
          'common.filters.buttonOptions.selectDeaderCodeUserRegistered',
      },
      FILTER_TAGS: {
        ACCOUNT: 'common.filters.filterTags.account',
      },
      PLACEHOLDERS: {
        ENTER_TEXT: 'common.filters.placeholders.enterText',
        SELECT_ONE: 'common.filters.placeholders.selectOne',
        SELECT_ALL_THAT_APPLY: 'common.filters.placeholders.selectAllThatApply',
      },
      DROPDOWN_OPTIONS: {
        CUSTOMER_STATUS: {
          ACTIVE: 'common.filters.dropdownOptions.customerStatus.active',
          INACTIVE: 'common.filters.dropdownOptions.customerStatus.inactive',
          OBSOLETE: 'common.filters.dropdownOptions.customerStatus.obsolete',
          PROSPECT: 'common.filters.dropdownOptions.customerStatus.prospect',
        },
        OWNERSHIP_TYPE_VALUES: {
          OWNED: 'common.filters.dropdownOptions.ownershipTypeValues.owned',
          LEASED: 'common.filters.dropdownOptions.ownershipTypeValues.leased',
          INVENTORY: 'common.filters.dropdownOptions.ownershipTypeValues.inventory',
          RENTAL: 'common.filters.dropdownOptions.ownershipTypeValues.rental',
          UNKNOWN: 'common.filters.dropdownOptions.ownershipTypeValues.unknown',
        },
      },
      VALIDATIONS: {
        REQUEST_TYPE_REQUIRED: 'common.filters.validations.requestTypeRequired',
        ONE_OR_MORE_CUSTOMER_REQUIRED: 'common.filters.validations.oneOrMoreCustomerRequired',
        ONE_CUSTOMER_REQUIRED: 'common.filters.validations.oneCustomerRequired',
      },
      EXTRA_INFO: {
        ONLY_PENDING_AND_ACCEPTED_INVITATIONS_DISPLAYED:
          'common.filters.extraInfo.onlyPendingAndAcceptedInvitationsDisplayed',
      },
      LINKS: {
        ADD_CUSTOMER_SELECTION: 'common.filters.links.addCustomerSelection',
        EDIT_CUSTOMER_SELECTION: 'common.filters.links.editCustomerSelection',
      },
    },
    STATUS: {
      PENDING: 'common.status.pending',
      ACCEPTED: 'common.status.accepted',
      REVOKED: 'common.status.revoked',
      APPROVED: 'common.status.approved',
      DENIED: 'common.status.denied',
      NEW: 'common.status.new',
    },
    AND: 'common.and',
    MENU: 'common.menu',
    SELECT_ALL: 'common.selectAll',
    CONTINUE: 'common.continue',
    CANCEL: 'common.cancel',
    DENY: 'common.deny',
    CLOSE: 'common.close',
    ID: 'common.id',
    BOLD_ID_WITH_NUMBER: 'common.boldIdWithNumber',
    CWS_ID_WITH_TOOLTIP: 'common.cwsIdWithTooltip',
    CWS_ID: 'common.cwsId',
    SAVE: 'common.save',
    STATUS_TITLE: 'common.statusTitle',
    SUBMIT: 'common.submit',
    CONFIRM: 'common.confirm',
    NEXT: 'common.next',
    APPLY: 'common.apply',
    APPROVE: 'common.approve',
    SAVE_AND_REVOKE: 'common.saveAndRevoke',
    OK: 'common.ok',
    YES: 'common.yes',
    NO: 'common.no',
    TO: 'common.to',
    FROM: 'common.from',
    NONE: 'common.none',
    ATTRIBUTE: 'common.attribute',
    UNAVAILABLE: 'common.unavailable',
    WARNING: 'common.warning',
    SEARCH: 'common.search',
    SEARCH_RESULTS: 'common.searchResults',
    CALENDAR_DATE_RANGE: 'common.calendarDateRange',
    CALENDAR_DATE_TIME: 'common.calendarDateTime',
    CITY: 'common.city',
    ADDRESS: 'common.address',
    ADDRESS_TYPE: 'common.addressType',
    INLINE_ADDRESS: 'common.inlineAddress',
    STREET_ADDRESS: 'common.streetAddress',
    PHONE: 'common.phone',
    PHONE_TYPE: 'common.phoneType',
    CELL_PHONE: 'common.cellPhone',
    BUSINESS_PHONE: 'common.businessPhone',
    ZIP_CODE: 'common.zipCode',
    ADVANCED_SEARCH: 'common.advancedSearch',
    FILTERS_BUTTON: 'common.filtersButton',
    CLEAR_ALL: 'common.clearAll',
    REMOVE: 'common.remove',
    REMOVE_ALL: 'common.removeAll',
    CLEAR_RESULTS: 'common.clearResults',
    CONTACT_SUPPORT: 'common.contactSupport',
    NO_DATA: 'common.noData',
    NO_DATA_EXISTS: 'common.noDataExists',
    PARENT_ID: 'common.parentId',
    DETAILS: 'common.details',
    DATE_TIME: 'common.dateTime',
    CATEGORY: 'common.category',
    CAT_CUSTOMER: 'common.catCustomer',
    TOTAL: 'common.total',
    SEE_DETAILS: 'common.seeDetails',
    MAKE_RULE_SELECTION: 'common.makeRuleSelection',
    APPLY_ALL: 'common.applyAll',
    APPLY_ANY: 'common.applyAny',
    FIELD: 'common.field',
    MATCH_RULE: 'common.matchRule',
    SEARCH_TEXT: 'common.searchText',
    ADD_NEW_ROW: 'common.addNewRow',
    CUSTOMER_NAME: 'common.customerName',
    PARENT_CUSTOMER_NAME: 'common.parentCustomerName',
    PARENT_CUSTOMER_ID: 'common.parentCustomerId',
    BACK: 'common.back',
    ROLE: 'common.role',
    OWNERSHIP: 'common.ownership',
    SEE_MORE: 'common.seeMore',
    SEE_LESS: 'common.seeLess',
    ASSET: 'common.asset',
    APPLY_OWNERSHIP: 'common.applyOwnership',
    ADD_ADMIN: 'common.addAdmin',
    CHANGE_ACCOUNT: 'common.changeAccount',
    SETTINGS: 'common.settings',
    SIGN_OUT: 'common.signOut',
    IMPORT: 'common.import',
    SKIP: 'common.skip',
    CLEAR_SEARCH: 'common.clearSearch',
    METADATA: {
      BASE: {
        TRY_REVISING_CRITERIA: 'common.metadata.base.tryRevisingCriteria',
        SEARCH_EMAIL: 'common.metadata.base.searchEmail',
        SEE_MORE_DETAILS: 'common.metadata.base.seeMoreDetails',
        SEE_LESS_DETAILS: 'common.metadata.base.seeLessDetails',
        NO_WORK_QUEUE: 'common.metadata.base.noWorkQueue',
      },
      ERRORS: {
        RETRIEVE_ROLES: 'common.metadata.errors.retrieveRoles',
        NO_RESULTS_FOUND: 'common.metadata.errors.noResultsFound',
        RETRIEVE_APPLICATION_CACHE: 'common.metadata.errors.retrieveApplicationCache',
      },
    },
    PAGINATION: 'common.pagination',
    CUSTOMER_LOOKUP: 'common.customerLookup',
    SELECT_CUSTOMERS: 'common.selectCustomers',
    ADDED: 'common.added',
    NO_MATCHING_CUSTOMERS_FOUND: 'common.noMatchingCustomersFound',
    SEE_MORE_DETAILS: 'common.seeMoreDetails',
    VIEW_DETAILS: 'common.viewDetails',
    VALIDATIONS: {
      NO_BLANK_SPACES: 'common.validations.noBlankSpaces',
      REQUIRED_FIELD: 'common.validations.requiredField',
      VALID_EMAIL: 'common.validations.validEmail',
      EXCEED_LENGTH: 'common.validations.exceedLength',
      SPECIAL_CHARACTERS: 'common.validations.specialCharacters',
      CONSECUTIVE_SPACES: 'common.validations.consecutiveSpaces',
      STATE_IS_REQUIRED: 'common.validations.stateIsRequired',
      STATE_CODE: 'common.validations.stateCode',
      COUNTRY_CODE: 'common.validations.countryCode',
      LESS_THAN_CHARACTER: 'common.validations.lessThanCharacter',
      MORE_THAN_CHARACTER: 'common.validations.moreThanCharacter',
      SPACE_NOT_ALLOWED: 'common.validations.spaceNotAllowed',
      PHONE_FORMAT: 'common.validations.phoneFormat',
      EMAIL: 'common.validations.email',
      MINIMUM_CHARACTERS: 'common.validations.minimumCharacters',
      MAXIMUM_CHARACTERS: 'common.validations.maximumCharacters',
      NOT_CAT_ASSET_EXCEED_LENGTH: 'common.validations.notCatAssetExceedLength',
      NOT_START_OR_END_WITH_HYPHEN: 'common.validations.notStartOrEndWithHyphen',
      NOT_START_OR_END_WITH_SLASH: 'common.validations.notStartOrEndWithSlash',
    },
    FAILED_COUNT: 'common.failedCount',
    SUCCESS_COUNT: 'common.successCount',
    NO_RECOMMENDATIONS_FOUND: 'common.noRecommendationsFound',
    CLICK_SKIP_NEXT_STEP: 'common.clickSkipNextStep',
    ITEMS_REMAINING: 'common.itemsRemaining',
    MAINTENANCE_BANNER: 'common.maintenanceBanner',
    SELECT_LANGUAGE: 'common.selectLanguage',
    LANGUAGE: 'common.language',
    USER_EMAIL_ADDRESS: 'common.userEmailAddress',
  },
  GLOBALS: {
    ERRORS: {
      FETCHING_DEALER_CODES_FAILED: 'globals.errors.fetchingDealerCodesFailed',
      FETCH_PERMISSION: 'globals.errors.fetchPermission',
    },
    HEADER: {
      RECOMMENDATIONS_USE_NAME: 'globals.header.recommendationsUseName',
    },
    APP_ERRORS: {
      NOT_FOUND: 'globals.appErrors.notFound',
      ENTITLEMENTS_HEADING: 'globals.appErrors.entitlementsHeading',
      ENTITLEMENTS_DESCRIPTION: 'globals.appErrors.entitlementsDescription',
      USER_MANAGEMENT_DESCRIPTION: 'globals.appErrors.userManagementDescription',
      USER_MANAGEMENT_ADVICE: 'globals.appErrors.userManagementAdvice',
    },
  },
  CUSTOMER_ASSET_WORKFLOW: {
    NOTIFICATIONS: {
      OWNERSHIP_UPDATE: 'customerAssetWorkflow.notifications.ownershipUpdate',
      OWNERSHIP_CREATION: 'customerAssetWorkflow.notifications.ownershipCreation',
      UPDATE_ASSETS_REJECTION_FEEDBACK:
        'customerAssetWorkflow.notifications.updateAssetsRejectionFeedback',
      UPDATE_ASSETS_OWNERSHIP_CHANGED:
        'customerAssetWorkflow.notifications.updateAssetsOwnershipChanged',
      DELETE_ASSETS_OWNERSHIP: 'customerAssetWorkflow.notifications.deleteAssetsOwnership',
      DELETE_ASSETS_OWNERSHIP_TEXT: 'customerAssetWorkflow.notifications.deleteAssetsOwnershipText',
    },
    ERRORS: {
      ASSETS_RECOMMENDATION_RETRIEVE_FAILED:
        'customerAssetWorkflow.errors.assetsRecommendationRetrieveFailed',
      ASSETS_RECOMMENDATION_FAILED: 'customerAssetWorkflow.errors.assetsRecommendationFailed',
      OWNERSHIP_FAILED: 'customerAssetWorkflow.errors.ownershipFailed',
      OWNERSHIP_UPDATE_FAILED: 'customerAssetWorkflow.errors.ownershipUpdateFailed',
      OWNERSHIP_DETAILS_FAILED: 'customerAssetWorkflow.errors.ownershipDetailsFailed',
      OWNERSHIP_CREATION_FAILED: 'customerAssetWorkflow.errors.ownershipCreationFailed',
      OWNERSHIP_RETRIEVE_FAILED: 'customerAssetWorkflow.errors.ownershipRetrieveFailed',
      DELETE_ASSETS_OWNERSHIP_FAILED: 'customerAssetWorkflow.errors.deleteAssetsOwnershipFailed',
      DELETE_ASSETS_OWNERSHIP_FAILED_TEXT:
        'customerAssetWorkflow.errors.deleteAssetsOwnershipFailedText',
    },
  },
  ACCESS_REQUESTS_SERVICE: {
    NOTIFICATIONS: {
      EDIT_ACCESS_REQUEST_APPROVED: 'accessRequestsService.notifications.editAccessRequestApproved',
      EDIT_ACCESS_REQUEST_REFRESHING:
        'accessRequestsService.notifications.editAccessRequestRefreshing',
      EDIT_ACCESS_REQUEST_DENIED: 'accessRequestsService.notifications.editAccessRequestDenied',
    },
    ERRORS: {
      GET_ACCESS_REQUEST: 'accessRequestsService.errors.getAccessRequest',
      COUNT_ACCESS_REQUEST: 'accessRequestsService.errors.countAccessRequest',
      EDIT_ACCESS_REQUEST_APPROVING: 'accessRequestsService.errors.editAccessRequestApproving',
      EDIT_ACCESS_REQUEST_DENIED: 'accessRequestsService.errors.editAccessRequestDenied',
    },
  },
  ACCESS_MANAGEMENT: {
    TITLE: {
      MANAGE_ACCESS: 'accessManagement.title.manageAccess',
      VIEW_ACCESS: 'accessManagement.title.viewAccess',
      ASSET_SECURITY: 'accessManagement.title.assetSecurity',
      SELECT_DEALER_CUSTOMERS: 'accessManagement.title.selectDealerCustomers',
      PENDING_ACCESS_REQUEST: 'accessManagement.title.pendingAccessRequest',
    },
    ERRORS: {
      UPDATE_USER_ACCESS: 'accessManagement.errors.updateUserAccess',
      SEARCH_USERS: 'accessManagement.errors.searchUsers',
      FIND_USER_FAILED: 'accessManagement.errors.findUserFailed',
    },
    NOTIFICATIONS: {
      UPDATE_USER_ACCESS: 'accessManagement.notifications.updateUserAccess',
      APPLICATION_ACCESS_UPDATE: 'accessManagement.notifications.applicationAccessUpdate',
    },
    ACTIONS: {
      SHOW_LESS_DEALER: 'accessManagement.actions.showLessDealer',
      SHOW_ALL_DEALER: 'accessManagement.actions.showAllDealer',
      SELECTED_COMPANY: 'accessManagement.actions.selectedCompany',
      SELECT_ONE: 'accessManagement.actions.selectOne',
    },
    INFO: {
      DOES_THIS_USER_WORK: 'accessManagement.info.doesThisUserWork',
      DOES_THIS_USER_HAVE_ACCESS: 'accessManagement.info.doesThisUserHaveAccess',
      GRANTS_USER_A_PRIVILEGE: 'accessManagement.info.grantsUserAPrivilege',
      NO_DEALER_CUSTOMERS_ASSOCIATED: 'accessManagement.info.noDealerCustomersAssociated',
      ONE_OR_MORE_DEALER_CUSTOMER: 'accessManagement.info.oneOrMoreDealerCustomer',
      COMPLETE_ACCESS_NEED_REVIEW: 'accessManagement.info.completeAccessNeedReview',
      COMPLETE_ACCESS_WORK_QUEUE: 'accessManagement.info.completeAccessWorkQueue',
      INCLUDES_CAT_CENTRAL_APP: 'accessManagement.info.includesCatCentralApp',
    },
    PLACEHOLDER: {
      SEARCH: 'accessManagement.placeholder.search',
    },
    LABELS: {
      USER: 'accessManagement.labels.user',
      WORKS_FOR_COMPANY: 'accessManagement.labels.worksForCompany',
      USERNAME: 'accessManagement.labels.username',
      ID: 'accessManagement.labels.id',
    },
    NO_DATA: {
      SELECT_FILTERS: 'accessManagement.noData.selectFilters',
      NO_USER_EXISTS: 'accessManagement.noData.noUserExists',
      NO_DATA: 'accessManagement.noData.noData',
      REVISING_SEARCH_CRITERIA: 'accessManagement.noData.revisingSearchCriteria',
      TECHNICAL_DIFFICULTY: 'accessManagement.noData.technicalDifficulty',
      TRY_AGAIN: 'accessManagement.noData.tryAgain',
    },
  },
  USER_MANAGEMENT: {
    ERRORS: {
      SEARCH_USERS: 'userManagement.errors.searchUsers',
      USER_UPDATING: 'userManagement.errors.userUpdating',
      USER_ROLE_UPDATE: 'userManagement.errors.userRoleUpdate',
    },
    NOTIFICATIONS: {
      ACCOUNT_CHANGE: 'userManagement.notifications.accountChange',
      USER_ROLE_UPDATE: 'userManagement.notifications.userRoleUpdate',
    },
  },
  ASSETS: {
    PLACEHOLDER: 'assets.placeholder',
    NOTIFICATIONS: {
      UPDATE_ASSET: 'assets.notifications.updateAsset',
      ADD_OWNER_SHIP: 'assets.notifications.addOwnerShip',
      ASSETS_UPDATE: 'assets.notifications.assetsUpdate',
    },
    ERRORS: {
      NO_DATA: 'assets.errors.noData',
      UPDATE_ASSET_FAILED: 'assets.errors.updateAssetFailed',
      SEARCH_ASSETS: 'assets.errors.searchAssets',
      SEARCH_CONSOLIDATION_DATA: 'assets.errors.searchConsolidationData',
      ADD_OWNER_SHIP: 'assets.errors.addOwnerShip',
      RETRIEVE_ASSET: 'assets.errors.retrieveAsset',
      COUNT_NOT_GET_ASSET_DETAILS: 'assets.errors.countNotGetAssetDetails',
      ASSETS_COUNT_FETCH: 'assets.errors.assetsCountFetch',
      UNABLE_RETRIEVE_ASSET_DATA: 'assets.errors.unableRetrieveAssetData',
      SERIAL_NUMBER_VALIDATION: 'assets.errors.serialNumberValidation',
      MINIMUM_THREE_SYMBOLS: 'assets.errors.minimumThreeSymbols',
      CAT_SERIAL_NUMBER_VALIDATION: 'assets.errors.catSerialNumberValidation',
      UNABLE_TO_CHECK_PENDING_OWNERSHIP_REQUEST:
        'assets.errors.unableToCheckPendingOwnershipRequest',
    },
    VIEW_ASSET: 'assets.viewAsset',
    NO_DATA: 'assets.noData',
    NO_DATA_SEARCH: 'assets.noDataSearch',
    ADD_ASSET: {
      TITLE: 'assets.addAsset.title',
      OWNERSHIP_ALREADY_EXISTS: 'assets.addAsset.ownershipAlreadyExists',
      OWNERSHIP_EXISTS_EXPLANATION: 'assets.addAsset.ownershipExistsExplanation',
      ADD_ASSET_STEPS: {
        ENTER_ASSET_INFO: 'assets.addAsset.addAssetSteps.enterAssetInfo',
        ASSIGN_OWNER: 'assets.addAsset.addAssetSteps.assignOwner',
      },
      MODAL: {
        MODAL_LABEL: 'assets.addAsset.modal.modalLabel',
        MODAL_TEXT: 'assets.addAsset.modal.modalText',
        MODAL_PLACEHOLDER: 'assets.addAsset.modal.modalPlaceholder',
      },
      REVIEW_RESULTS: {
        OWNERSHIP_DETAILS: 'assets.addAsset.reviewResults.ownershipDetails',
        ASSET_DETAILS: 'assets.addAsset.reviewResults.assetDetails',
        RECORDS_NOT_CREATED: 'assets.addAsset.reviewResults.recordsNotCreated',
      },
      EXISTING_OWNERSHIP: {
        SEE_MORE: 'assets.addAsset.existingOwnership.seeMore',
        TITLE: 'assets.addAsset.existingOwnership.title',
        DESCRIPTION: 'assets.addAsset.existingOwnership.description',
      },
      EXISTING_RECORD: {
        TITLE: 'assets.addAsset.existingRecord.title',
        DESCRIPTION: 'assets.addAsset.existingRecord.description',
      },
      CONSOLIDATION: {
        IN_PROGRESS: 'assets.addAsset.consolidation.inProgress',
        DESCRIPTION: 'assets.addAsset.consolidation.description',
        UNDERGOING: 'assets.addAsset.consolidation.undergoing',
      },
    },
    MANAGE_ASSETS: {
      ADD_NEW_OWNER: 'assets.manageAssets.addNewOwner',
      ASSET_DETAILS_TITLE: 'assets.manageAssets.assetDetailsTitle',
      MANAGE_ASSETS_TITLE: 'assets.manageAssets.manageAssetsTitle',
      OWNERSHIP_RECORDS_INFO: 'assets.manageAssets.ownershipRecordsInfo',
      OWNERSHIP_RECORDS_TITLE: 'assets.manageAssets.ownershipRecordsTitle',
      EDIT_ASSET_DETAILS: 'assets.manageAssets.editAssetDetails',
      EDIT_DEALER_ASSET_ID: 'assets.manageAssets.editDealerAssetId',
      CHANGE_DEALER_ASSET_ID: 'assets.manageAssets.changeDealerAssetId',
      PLEASE_NOTE_TITLE: 'assets.manageAssets.pleaseNoteTitle',
      PENDING_CONSOLIDATION_NOTE: 'assets.manageAssets.pendingConsolidationNote',
      REMOVE_ASSET_NOTIFICATION: 'assets.manageAssets.removeAssetNotification',
    },
    EDIT_ASSET: {
      TITLE: 'assets.editAsset.title',
    },
    VIEW_ASSETS: 'assets.viewAssets',
    CATERPILLAR_ASSET: 'assets.caterpillarAsset',
    NOT_CATERPILLAR_ASSET: 'assets.notCaterpillarAsset',
    CHANGE_DEALER_ASSET_ID: 'assets.changeDealerAssetID',
    COLUMNS: {
      ASSET_ID: 'assets.columns.assetID',
      MAKE_AND_MODEL: 'assets.columns.makeAndModel',
      MAKE: 'assets.columns.make',
      SERIAL_NUMBER: 'assets.columns.serialNumber',
      PRODUCT_FAMILY: 'assets.columns.productFamily',
      MFG_YEAR: 'assets.columns.mfgYear',
      DEALER: 'assets.columns.dealer',
      DEALER_ASSET_ID: 'assets.columns.dealerAssetID',
      DEALER_CUSTOMER: 'assets.columns.dealerCustomer',
      MODEL: 'assets.columns.model',
      SUBSCRIBED: 'assets.columns.subscribed',
      SUBSCRIPTION: 'assets.columns.subscription',
      DELETE_OWNERSHIP_RECORD: 'assets.columns.deleteOwnershipRecord',
    },
    VALIDATIONS: {
      SERIAL_REQUIRED: 'assets.validations.serialRequired',
      SERVER_ISSUE: 'assets.validations.serverIssue',
      HUNDRED_CHARACTER_LIMIT: 'assets.validations.hundredCharacterLimit',
      PRODUCT_FAMILY_CHARACTER_LIMIT: 'assets.validations.productFamilyCharacterLimit',
      MODEL_CHARACTER_LIMIT: 'assets.validations.modelCharacterLimit',
      PRODUCT_FAMILY_IS_SHORTER: 'assets.validations.productFamilyIsShorter',
      SERIAL_NUMBER_CHARACTER_MAX_LIMIT: 'assets.validations.serialNumberCharacterMaxLimit',
      SERIAL_NUMBER_IS_SHORTER: 'assets.validations.serialNumberIsShorter',
      SERIAL_NUMBER_UNSUPPORTED_CHARACTERS: 'assets.validations.serialNumberUnsupportedCharacters',
    },
    ADD_OWNER_POPOVER: 'assets.addOwnerPopover',
    INACTIVE_OWNERSHIP_POPOVER_TEXT: 'assets.inactiveOwnershipPopoverText',
    EXPIRE_SUBSCRIPTIONS_WARNING: 'assets.expireSubscriptionsWarning',
    EXPIRE_ASSET_WARNING: 'assets.expireAssetWarning',
    OWNER_SHIP_RECORD_EXPIRED: 'assets.ownerShipRecordExpired',
    ADD_ASSET_FOOTER: 'assets.addAssetFooter',
    ADD_ASSET_RECORD_EXISTS: 'assets.addAssetRecordExists',
    EXISTING_ASSET_DISCLAIMER: 'assets.existingAssetDisclaimer',
  },
  RECOMMENDATION: {
    RECOMMENDATION_NAME: {
      DEALER_CUSTOMER: 'recommendation.recommendationName.dealerCustomer',
      CAT_CUSTOMER: 'recommendation.recommendationName.catCustomer',
      NEW_DEALER_CUSTOMER: 'recommendation.recommendationName.newDealerCustomer',
      CAT_DEALER_CUSTOMER: 'recommendation.recommendationName.catDealerCustomer',
    },
    ITEMS_FEEDBACK: {
      ICONS_EXPLAINED: 'recommendation.itemsFeedback.iconsExplained',
    },
    ERRORS: {
      FEEDBACK_FETCH: 'recommendation.errors.feedbackFetch',
      FETCH_RECOMMENDATION_ITEMS: 'recommendation.errors.fetchRecommendationItems',
      EDIT_COMMENT: 'recommendation.errors.editComment',
      DELETE_COMMENT: 'recommendation.errors.deleteComment',
      FEEDBACK_SAVE: 'recommendation.errors.feedbackSave',
    },
    NOTIFICATIONS: {
      COMMENT_ADD: 'recommendation.notifications.commentAdd',
      RECOMMENDATION_REMOVE: 'recommendation.notifications.recommendationRemove',
      FEEDBACK_APPLY: 'recommendation.notifications.feedbackApply',
      LIMITED_VIEWING_OF_ASSOCIATIONS: 'recommendation.notifications.limitedViewingOfAssociations',
    },
  },
  PREFERENCES: {
    ERRORS: {
      UNABLE_TO_SAVE: 'preferences.errors.unableToSave',
      UNABLE_RETRIEVE_PREFERENCES: 'preferences.errors.unableRetrievePreferences',
      SETTINGS_SAVE: 'preferences.errors.settingsSave',
    },
    NOTIFICATIONS: {
      SETTINGS_SAVE: 'preferences.notifications.settingsSave',
    },
  },
  SETTINGS: {
    PAGE_VIEW: 'settings.pageView',
    CUSTOMER_DATA: 'settings.customerData',
    DEFAULT_DEALER_CODE: 'settings.defaultDealerCode',
    FILTER_TAGS: 'settings.filterTags',
    SHOW_CUSTOMERS: 'settings.showCustomers',
    SHOW_CUSTOMERS_EXPLAINED: 'settings.showCustomersExplained',
    SHOW_CUSTOMER_USER_DATA: 'settings.showCustomerUserData',
    SHOW_CUSTOMER_USER_DATA_EXPLAINED: 'settings.showCustomerUserDataExplained',
    SELECT_DEFAULT_DEALER_CODE_USER_ASSOCIATION: 'settings.selectDefaultDealerCodeUserAssociation',
    SELECT_DEFAULT_DEALER_CODE_USER_ASSOCIATION_EXPLAINED:
      'settings.selectDefaultDealerCodeUserAssociationExplained',
    SHOW_APPLIED_FILTERS: 'settings.showAppliedFilters',
    SHOW_APPLIED_FILTERS_EXPLAINED: 'settings.showAppliedFiltersExplained',
    VALIDATIONS: {
      ACCOUNT_SELECTION_REQUIRED: 'settings.validations.accountSelectionRequired',
    },
    BRAND_CUSTOMERS: 'settings.brandCustomers',
  },
  CAT_FINANCIAL_PRODUCTS: {
    INLINE_COLUMNS: {
      SOURCE_ID: 'catFinancialProducts.inlineColumns.sourceId',
      PARTY_ID: 'catFinancialProducts.inlineColumns.partyId',
      ACCOUNT_SOURCES: 'catFinancialProducts.inlineColumns.accountSources',
    },
    REMOVE_PRODUCT: 'catFinancialProducts.removeProduct',
    REMOVE_PRODUCT_CONFIRMATION: 'catFinancialProducts.removeProductConfirmation',
    REMOVE_PRODUCT_SUCCESS_NOTIFICATION: 'catFinancialProducts.removeProductSuccessNotification',
  },
};

export default TEXT;
