import { OptionsObject, VariantType } from 'notistack';
import { OAuth2Client, CodeVerificationStrategy, TokenInformation } from '@kolorado/oauth';
import {
  ContainsFilter,
  Filters,
  StringEqualsFilter,
  CustomerPhone,
  BooleanEqualsFilter,
  BrandCode,
  DealerCustomerOrgAddressType,
  SearchDealerCustomerResponse,
} from '../entities/customer-master-v1/models';
import { SubdivisionSearchResponseItem } from '../entities/countries-v1/models';
import { enqueueSnackbarAction } from '../store/snackbar/actions';
import { AppThunkDispatch } from '../store';
import { Country } from '../globals/old-countries-data';
import { AuthInfo } from '../model/types';
import { loginActionNames } from '../store/login/types';
import { EnvironmentVariables } from '../environments';
import dayjs from 'dayjs';
import { selectAccountsByPermissionType } from '../store/permissions/selectors';
import { UserBasedPermission } from '../entities/entitlements-v1/userBasedPermission';
import { PermissionsHashMapForUserBased } from '../entities/entitlements-v1/permissionsHashMapForUserBased';
import {
  invitationsPermissionMethods,
  invitationsPermissionName,
} from '../store/invitations/types';
import {
  accessManagementPermissionMethods,
  accessManagementPermissionName,
} from '../store/access-management/types';
import {
  accessRequestPermissionMethods,
  accessRequestPermissionName,
} from '../store/access-request/types';
import { CustomerData, CustomerDataType } from '../pages/my-settings/constants';
import { Condition } from '../entities/entitlements-v1/condition';
import { PartyType } from '../entities/entitlements-v1/partyType';
import { Conditions } from '../entities/entitlements-v1/conditions';
import { ReactElement } from 'react';
export type SearchRequestType = {
  businessName?: string;
  customerId?: string;
  streetAddress1?: string;
  city?: string;
  stateOrProvince?: string;
  postalCode?: string;
  country?: string;
  hasAssociationToMyDealerCodes?: boolean;
};

export const getDuplicateCustomerRequest = (input: {
  customerOrganizationBusinessName: string;
  countryCode: string;
  stateOrProvinceCode: string;
  customerData: BrandCode;
}): Filters => {
  return {
    logicalExpression: '$0 & $1',
    filters: [
      {
        propertyName: 'customerOrganizationBusinessName',
        type: 'contains',
        value: input.customerOrganizationBusinessName,
      },
      {
        propertyName: 'brandCode',
        type: 'stringEquals',
        values: [input.customerData || CustomerData.CAT],
      },
    ],
  };
};

export const trimAll = (obj: any) => {
  Object.keys(obj).map((key) => {
    if (typeof obj[key] === 'string') {
      obj[key] = obj?.[key]?.trim();
    } else {
      trimAll(obj[key]);
    }
    return obj;
  });
  return obj;
};

export const getSearchRequest = (input: SearchRequestType): Filters => {
  const {
    businessName,
    customerId,
    streetAddress1,
    city,
    stateOrProvince,
    postalCode,
    hasAssociationToMyDealerCodes,
  } = input;
  let expressions = '';
  let numberOfFilters = 0;
  const conditions: Array<ContainsFilter | StringEqualsFilter | BooleanEqualsFilter> = [];
  if (hasAssociationToMyDealerCodes) {
    numberOfFilters += 1;
    expressions += '$0';
    conditions.push({
      propertyName: 'hasAssociationToMyDealerCodes',
      type: 'booleanEquals',
      value: true,
    });
  }
  if (businessName && businessName !== '') {
    expressions += `${expressions === '' ? '$0' : ` & $${numberOfFilters}`}`;
    numberOfFilters += 1;
    conditions.push({
      propertyName: 'customerOrganizationBusinessName',
      type: 'contains',
      value: `${businessName}`,
    });
  }

  if (customerId) {
    expressions += `${expressions === '' ? '$0' : ` & $${numberOfFilters}`}`;
    numberOfFilters += 1;
    conditions.push({
      propertyName: 'customerOrganizationIdentifier',
      type: 'contains',
      value: `${customerId}`,
    });
  }

  if (streetAddress1 || city || stateOrProvince || postalCode) {
    expressions += ' & (';
    if (streetAddress1) {
      expressions += ` $${numberOfFilters}`;
      numberOfFilters += 1;
      conditions.push({
        propertyName: 'address1',
        type: 'contains',
        value: streetAddress1,
      });
    }
    if (city) {
      expressions += numberOfFilters > 1 ? ' &' : '';
      expressions += ` $${numberOfFilters}`;
      numberOfFilters += 1;
      conditions.push({
        propertyName: 'cityName',
        type: 'contains',
        value: city,
      });
    }
    if (stateOrProvince) {
      expressions += numberOfFilters > 1 ? ' &' : '';
      expressions += ` $${numberOfFilters}`;
      numberOfFilters += 1;
      conditions.push({
        propertyName: 'stateOrProvinceCode',
        type: 'stringEquals',
        values: [`${stateOrProvince}`],
      });
    }
    if (postalCode) {
      expressions += numberOfFilters > 1 ? ' &' : '';
      expressions += ` $${numberOfFilters}`;
      numberOfFilters += 1;
      conditions.push({
        propertyName: 'postalCode',
        type: 'stringEquals',
        values: [`${postalCode}`],
      });
    }
    expressions += ' )';
  }
  return {
    logicalExpression: expressions,
    filters: conditions,
  };
};

export function updatePhoneValues(businessPhone: string, mobilePhone: string) {
  const result: Array<CustomerPhone> = [];
  if (businessPhone) {
    result.push({ phoneType: 'PRIMARY', phoneNumber: businessPhone });
  }
  if (mobilePhone) {
    result.push({ phoneType: 'MOBILE', phoneNumber: mobilePhone });
  }
  return result;
}

export function getValueFromObject(value: any, path: string, defaultValue?: any) {
  return String(path)
    .split('.')
    .reduce((accumulator, v) => {
      try {
        // eslint-disable-next-line no-param-reassign
        accumulator = accumulator[v];
      } catch {
        return defaultValue;
      }
      return accumulator;
    }, value);
}

export function randomNumberString() {
  return (Date.now() + Math.random()).toString();
}

const noop = (): void => undefined;

export function disableReactDevelopmentTools(): void {
  // eslint-disable-next-line no-underscore-dangle
  const DEV_TOOLS = (window as any).__REACT_DEVTOOLS_GLOBAL_HOOK__;

  if (typeof DEV_TOOLS === 'object') {
    for (const [key, value] of Object.entries(DEV_TOOLS)) {
      DEV_TOOLS[key] = typeof value === 'function' ? noop : undefined;
    }
  }
}

export const formattedCustomerDetails = (stringValue: string) => {
  return stringValue
    .toLocaleLowerCase()
    .split(' ')
    .map((word: string) => {
      return word.slice(0, 1).toLocaleUpperCase() + word.slice(1);
    })
    .join(' ');
};

export const getStateAbbreviations = (
  inputCountryCode: string,
  inputState: string,
  countriesData: Country,
): Array<string> => {
  return (
    countriesData?.[inputCountryCode]?.states?.find((state: any) => state.title === inputState)
      ?.abbreviations || []
  );
};

export type AddressValues = {
  businessPhone: string;
  city: string;
  stateOrProvince: string;
  customerName: string;
  countryCode: string;
  streetAddress1: string;
  streetAddress2: string;
  streetAddress3: string;
  zipCode: string;
  errorCode?: string;
  errorDescription?: string;
};

export type xmlParsedAddressProperties = {
  addressLine1?: string;
  addressLine2?: string;
  addressLine3?: string;
  cityState?: string;
  name?: string;
  phoneNumber?: string;
  countryCode?: string;
  zipCode?: string;
  errorCode?: string;
  errorDescription?: string;
};

const splitCityState = (cityState: string) => {
  const cityStateArray = cityState.split(' ');
  const city = cityStateArray
    .splice(0, cityStateArray.length - 1)
    .join(' ')
    .replace(/,/g, '');
  const state = cityStateArray[cityStateArray.length - 1];
  return [city, state];
};

export const getFullStateFromAbbreviation = (
  abbreviatedState: string,
  countryCode: string,
  countriesData: Country,
) => {
  let fullState = abbreviatedState;
  const countriesAndStateData = countriesData?.[countryCode];
  if (countriesAndStateData?.states) {
    for (let state of countriesAndStateData.states) {
      if (state.abbreviations && state.abbreviations.includes(abbreviatedState)) {
        fullState = state.code;
        break;
      }
    }
  }
  return fullState;
};

export const formatStateData = (states: Array<SubdivisionSearchResponseItem>) => {
  let stateList = [];
  if (states) {
    for (let state of states) {
      if (state.subdivisionCode && state.subdivisionName) {
        stateList.push({
          title: state.subdivisionName.toLocaleUpperCase(),
          code: state.subdivisionCode,
        });
      }
    }
  }

  return stateList;
};

export const formatCustomerCleanSpaces = (xmlParsedAddress: xmlParsedAddressProperties) => {
  const xmlParsedAddressNoSpaces = {} as xmlParsedAddressProperties;
  let field: keyof xmlParsedAddressProperties;
  for (field in xmlParsedAddress) {
    if (xmlParsedAddress[field]) {
      xmlParsedAddressNoSpaces[field] = xmlParsedAddress?.[field]?.trim();
    }
  }
  return xmlParsedAddressNoSpaces;
};

export const formatCustomerAddress = (
  xmlParsedAddress: xmlParsedAddressProperties,
  // countriesData: Country,
  countriesData: any,
) => {
  let formattedCustomerAddress = {} as AddressValues;
  let formattedXmlAddress = {} as xmlParsedAddressProperties;

  formattedXmlAddress = formatCustomerCleanSpaces(xmlParsedAddress);

  const {
    name,
    addressLine1,
    addressLine2,
    addressLine3,
    cityState,
    phoneNumber,
    zipCode,
    countryCode,
    errorCode,
    errorDescription,
  } = formattedXmlAddress;

  if (name) formattedCustomerAddress.customerName = name;
  if (
    formattedCustomerAddress &&
    (addressLine1 === undefined || addressLine1 === '') &&
    (addressLine2 !== undefined || addressLine2 !== '') &&
    (addressLine3 !== undefined || addressLine3 !== '')
  ) {
    formattedCustomerAddress.streetAddress1 = addressLine2 || '';
    formattedCustomerAddress.streetAddress2 = addressLine3 || '';
  } else {
    if (addressLine1) formattedCustomerAddress.streetAddress1 = addressLine1;
    if (addressLine2) formattedCustomerAddress.streetAddress2 = addressLine2;
    if (addressLine3) formattedCustomerAddress.streetAddress3 = addressLine3;
  }

  formattedCustomerAddress.countryCode =
    (countryCode && countriesData[countryCode]?.code) || countryCode || '';

  if (countryCode === 'US') formattedCustomerAddress.countryCode = 'USA';

  if (cityState && cityState.split(' ').length > 1) {
    const separatedCityState = splitCityState(cityState);
    formattedCustomerAddress.city = separatedCityState[0];
    formattedCustomerAddress.stateOrProvince = countryCode
      ? getFullStateFromAbbreviation(separatedCityState[1], countryCode, countriesData)
      : separatedCityState[1];
  } else {
    formattedCustomerAddress.city = cityState || '';
  }

  if (phoneNumber) formattedCustomerAddress.businessPhone = phoneNumber;
  if (zipCode) formattedCustomerAddress.zipCode = zipCode;
  if (errorCode) formattedCustomerAddress.errorCode = errorCode;
  if (errorDescription) formattedCustomerAddress.errorDescription = errorDescription;
  return formattedCustomerAddress;
};

export const printFeatureFlagConsole = (
  flagsObject: {
    [key: string]: boolean | string;
  },
  currentEnv: string,
) => {
  console.info(`Current environment: ${currentEnv}`);
  console.group('Feature Flags');

  for (let key of Object.keys(flagsObject)) {
    console.info(
      `${key}: ${
        flagsObject[key]
          ? typeof flagsObject[key] !== 'object'
            ? 'ON'
            : JSON.stringify(flagsObject[key])
          : 'OFF'
      }`,
    );
  }
  console.groupEnd();
};

export const formatDetailedErrorMessage = (error: any) => {
  let errorMessage = error?.response?.data?.description || '';
  if (
    error?.response?.data?.details?.length > 0 &&
    error?.response?.data?.details?.[0]?.message &&
    errorMessage.length > 0
  ) {
    errorMessage = `${errorMessage}, ${error?.response?.data?.details?.[0]?.message}`;
  }
  return errorMessage;
};

export const formatErrorMessageDetailsOnly = (error: any) => {
  let errorMessage = error?.description || '';
  if (
    error?.details &&
    error?.details?.length > 0 &&
    error?.details?.[0]?.message &&
    errorMessage.length > 0
  ) {
    errorMessage = `${error?.details?.[0]?.message}`;
  }
  return errorMessage;
};

// this will return valid list of emails/phones/addresses with its primary object
export const getListOfValidDataWithPrimaryObj = (data: any, dataFor: string, primary?: string) => {
  const validData = data?.filter((item: any) => item[`${dataFor}`]);
  let primaryObj =
    validData && primary ? validData.find((ele: any) => ele[primary]) || validData[0] : undefined;
  return { validData, primaryObj };
};
export const filterUnmatchedElement = (data: any, obj: any) => {
  return data?.filter((elm: any) => {
    let allMatched: any = true;
    for (let [key, value] of Object.entries(obj)) {
      if (elm[key] !== value) {
        allMatched = false;
        break;
      }
    }
    return allMatched === false;
  });
};
export const showNotification = (
  dispatch: AppThunkDispatch,
  variant: VariantType,
  message: string | ReactElement,
  options?: OptionsObject,
  key?: string,
) => {
  dispatch(
    enqueueSnackbarAction({
      key: key || (Date.now() + Math.random()).toString(),
      message,
      options: {
        variant,
        persist: variant === 'error',
        ...options,
      },
    }),
  );
};

export const isString = (obj: any) => {
  return typeof obj === 'string';
};

export const createLogicalExpression = (length: number, joiner: '&' | '|') => {
  let logicalExpression = '';
  for (let idx = 0; idx < length; idx++) {
    logicalExpression += `${idx > 0 ? ` ${joiner} $${idx}` : '$' + idx}`;
  }
  return logicalExpression.trim();
};

export const formatDate = (date: string | Date) => {
  const newDate = new Date(date);
  const formattedDate = `${newDate.toLocaleString('default', {
    month: 'long',
  })} ${newDate.getDate()}, ${newDate.getFullYear()}`;
  return formattedDate;
};

export const sortAlphaNumeric = (a: string, b: string) =>
  a.localeCompare(b, 'en', { numeric: true });

export const timeGate = (startDateMilis: number, endDateMilis: number) => {
  const currentTime = dayjs().valueOf();
  return currentTime >= startDateMilis && currentTime <= endDateMilis;
};

export function capitalizeString(string?: string) {
  if (!string) {
    return '';
  }
  return string.charAt(0).toLocaleUpperCase() + string.slice(1).toLocaleLowerCase();
}

export function removeLastComma(originalString: string) {
  if (originalString && originalString.length > 0) {
    return originalString[originalString.length - 1] === ','
      ? originalString.slice(0, -1)
      : originalString;
  }
  return originalString;
}

export const refreshAuthToken = async (
  incomingAuthInfo: AuthInfo,
  dispatch: AppThunkDispatch,
  environmentVariables: EnvironmentVariables,
) => {
  const client = OAuth2Client.of({
    uri: environmentVariables.authURI,
    tokenURI: environmentVariables.tokenURI,
    clientID: 'CustomerMasterUI_ac_client',
    redirectURI: environmentVariables.redirectURI,
    antiForgeryTtl: environmentVariables.antiForgeryTtl,
    proofCodeTtl: environmentVariables.proofCodeTtl,
    leniency: environmentVariables.leniency,
    oauthAdapter: 'OAuthAdapterCCDS',
    responseType: 'code',
    codeVerification: CodeVerificationStrategy.SYNCHRONIZED,
  });
  dispatch({ type: loginActionNames.REFRESH_TOKEN_LOADING, payload: true });

  if (incomingAuthInfo?.refreshToken) {
    return client
      .refresh(incomingAuthInfo.refreshToken || '')
      .then((tokenInformation: TokenInformation) => {
        const {
          loginId,
          metadata: { catrecid, client_id: clientId, catafltncode },
          token,
          refreshToken,
          expiresIn,
        } = tokenInformation;
        const authInfo: AuthInfo = {
          loginId,
          catrecid,
          clientId,
          token,
          catafltncode,
          refreshToken,
          expiresIn,
        };
        dispatch({ type: loginActionNames.SET_LOGIN_TIME, payload: Date.now() });
        dispatch({ type: loginActionNames.SET_CLIENT, payload: authInfo });
        dispatch({ type: loginActionNames.REFRESH_TOKEN_LOADING, payload: false });

        return authInfo;
      })
      .catch((error: any) => {
        dispatch({ type: loginActionNames.REFRESH_TOKEN_LOADING, payload: false });

        return dispatch({ type: loginActionNames.SET_LOGIN_ERROR, payload: error });
      });
  }
};

export const getViewInvitationsPermissions = (permissionsResponse: UserBasedPermission) => {
  return selectAccountsByPermissionType(
    (permissionsResponse as UserBasedPermission)?.dataPermissions as PermissionsHashMapForUserBased,
    invitationsPermissionName,
    invitationsPermissionMethods.view,
  );
};
export const getViewAccessManagementPermissions = (permissionsResponse: UserBasedPermission) => {
  return selectAccountsByPermissionType(
    (permissionsResponse as UserBasedPermission)?.dataPermissions as PermissionsHashMapForUserBased,
    accessManagementPermissionName,
    accessManagementPermissionMethods.search,
  );
};
export const getViewAccessRequestPermissions = (permissionsResponse: UserBasedPermission) => {
  return selectAccountsByPermissionType(
    (permissionsResponse as UserBasedPermission)?.dataPermissions as PermissionsHashMapForUserBased,
    accessRequestPermissionName,
    accessRequestPermissionMethods.view,
  );
};

export const getCommonAccounts = (permissionsResponse: UserBasedPermission) => {
  const invitations = getViewInvitationsPermissions(permissionsResponse);
  const accessManagement = getViewAccessManagementPermissions(permissionsResponse);
  const accessRequest = getViewAccessRequestPermissions(permissionsResponse);
  // eslint-disable-next-line unicorn/prefer-spread
  return Array.from(new Set([...invitations, ...accessManagement, ...accessRequest]));
};

export const getGlobalDirectoryUsers = (permissionsResponse: UserBasedPermission) => {
  return (
    permissionsResponse?.dataPermissions?.ciamUsers?.customerAssociation?.filterConditions?.[0]
      ?.partyNumbers || ['']
  );
};

export const onlyUnique = (value: string, index: number, array: Array<string>) => {
  return array.indexOf(value) === index;
};

export const httpErrorTextRemoval = (description: string, statusText: string) => {
  let parsedErrorMessage = description.replace(`${statusText}, `, '');
  if (parsedErrorMessage.length === description.length)
    parsedErrorMessage = description.replace(statusText, '');
  return parsedErrorMessage.trimStart();
};
export const getBrand = (customerData: string) => {
  let brand = CustomerData.CAT;
  // eslint-disable-next-line unicorn/no-array-for-each
  Object.keys(CustomerData).forEach((key) => {
    if (CustomerData[key as CustomerDataType] === customerData) brand = customerData;
  });
  return brand;
};

export const getAddress = (row: SearchDealerCustomerResponse) => {
  if (row?.dealerCustomerAddresses?.length) {
    const address = row.dealerCustomerAddresses.find(
      (x) => x.addressType === DealerCustomerOrgAddressType.MAINOFFICE,
    );
    if (address) return address;
    return row.dealerCustomerAddresses[0];
  }
  return undefined;
};
export const getAssetPartyNumber = (
  permissionsResponse: UserBasedPermission,
  actionName: string,
) => {
  if (permissionsResponse?.dataPermissions?.assetDetail?.[actionName]?.filterConditions) {
    const filterConditions: Condition | undefined = (
      permissionsResponse.dataPermissions?.assetDetail?.[actionName]?.filterConditions as Conditions
    )?.length
      ? (
          permissionsResponse.dataPermissions?.assetDetail?.[actionName]
            ?.filterConditions as Conditions
        )[0]
      : undefined;

    return filterConditions?.partyNumbers && filterConditions?.partyNumbers?.length > 0
      ? filterConditions?.partyNumbers.includes(PartyType.CAT)
        ? PartyType.CAT
        : filterConditions?.partyNumbers?.[0]
      : PartyType.CAT;
  }
};

export const appendLogicalExpression = (
  expression: string,
  index: number,
  dataLength: number,
  isWithBrackets?: boolean,
  isNotIncluded?: boolean,
) => {
  const prefix = isNotIncluded ? '!$' : '$';

  if (dataLength === 1) {
    return `${prefix}${index}`;
  }

  const newExpression =
    expression +
    (expression.length ? ` & ${prefix}${index}` : `${isWithBrackets ? '(' : ''}${prefix}${index}`);

  return dataLength === index + 1 ? `${newExpression}${isWithBrackets ? ')' : ''}` : newExpression;
};

export const displayFirstNameLastName = (
  firstName: string | undefined,
  lastName: string | undefined,
) => {
  if (firstName === undefined && lastName === undefined) {
    return '--';
  } else {
    return `${firstName ? firstName : ''} ${lastName ? lastName : ''}`.trim();
  }
};

export const isCatAdminUser = (permissionsResponse: UserBasedPermission) => {
  return (
    (permissionsResponse as UserBasedPermission) &&
    (permissionsResponse as UserBasedPermission).primaryPartyType &&
    (permissionsResponse as UserBasedPermission).primaryPartyType === PartyType.CAT
  );
};
export const isIdeographicText = (input: string) =>
  /[\u3000-\u30FF\u4E00-\u9FFF\uAC00-\uD7AF]/.test(input);
