import React, { FC, ReactElement, useState, RefObject, createRef, useRef } from 'react';
import { AppBar, Toolbar, Grid, Box, Typography, useTheme } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import clsx from 'clsx';
import shortid from 'shortid';

import useStyles from './styles';
import logo from '../../assets/images/header-logo.png';
import UserOptions from './components/user-options';
import ProfileImage from '../../assets/icons/profile-image';
import ProfileImageSecondary from '../../assets/icons/profile-image-secondary';
import useOutsideClick from '../../hooks/handle-click-outside';
import { UsersSearchResponse } from '../../entities/user-management-v1/usersSearchResponse';

export type HeaderProperties = {
  headerComponents?: ReactElement[];
  open?: boolean;
  handleDrawerClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  userInfo?: UsersSearchResponse;
};

const Header: FC<HeaderProperties> = (props: HeaderProperties) => {
  const reference: RefObject<HTMLDivElement> = createRef();
  const userInfoReference: RefObject<HTMLDivElement> = createRef();
  const userOptionsReference: RefObject<HTMLDivElement> = createRef();
  const { classes } = useStyles();
  const theme = useTheme();
  const { headerComponents, handleDrawerClick, open, userInfo } = props;
  const [userInfoFlag, setUserInfoFlag] = useState<boolean>(false);
  const [hoverEventFlag, setHoverEventFlag] = useState<boolean>(false);

  const wrapperRefUserOptions = useRef(null);
  useOutsideClick([wrapperRefUserOptions, reference], () => {
    setUserInfoFlag(false);
  });
  return (
    <Grid container className={clsx(classes.outerContainer)}>
      <Grid item xs={12}>
        <AppBar
          className={clsx(classes.appbar, classes.container)}
          data-testid="header-app-bar"
          position="fixed"
        >
          <Toolbar disableGutters>
            <Grid container direction="row">
              <Grid md={2} sm={3} className={classes.item} item>
                <Grid container direction="row">
                  <Grid item md={3} sm={2} xs={1}>
                    <IconButton
                      data-testid="header-icon-button"
                      className={classes.menuButton}
                      edge="start"
                      color="inherit"
                      aria-label="menu"
                      onClick={handleDrawerClick}
                      size="large"
                    >
                      {!open ? (
                        <MenuIcon data-testid="header-menu-icon" />
                      ) : (
                        <CloseIcon data-testid="header-close-icon" />
                      )}
                    </IconButton>
                  </Grid>
                  <Grid item md={6} sm={5} xs={4}>
                    <Box className={classes.toolbar}>
                      <img className={classes.logo} src={logo} alt="cat logo" />
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
              <Grid md={8} sm={7} item>
                <Box pr={2} borderRight="1px solid #464646">
                  <Grid
                    className={classes.container}
                    container
                    justifyContent="flex-end"
                    alignItems="stretch"
                  >
                    {headerComponents?.map((component: ReactElement) => (
                      <Box display="flex" ml={1} key={shortid.generate()}>
                        <Grid className={classes.item} item>
                          {component}
                        </Grid>
                      </Box>
                    ))}
                  </Grid>
                </Box>
              </Grid>
              <Grid md={2} sm={2} item>
                <div
                  className={classes.boxPointer}
                  ref={reference}
                  onMouseEnter={() => setHoverEventFlag(true)}
                  onMouseLeave={() => setHoverEventFlag(false)}
                >
                  <Box
                    height={theme.spacing(8)}
                    role="button"
                    data-testid="user-info"
                    id="user-info"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    className={clsx({
                      [classes.userInfo]: true,
                      [classes.clickedUserInfo]: userInfoFlag,
                    })}
                    onClick={() => setUserInfoFlag(!userInfoFlag)}
                  >
                    <Box>
                      <Typography>
                        {userInfo?.users?.[0]?.primaryPartyDetails?.partyName || 'READ ONLY USER'}
                      </Typography>
                    </Box>
                    <Box>
                      {userInfoFlag || hoverEventFlag ? (
                        <ProfileImage className={classes.avatar} data-testid="profile-image" />
                      ) : (
                        <ProfileImageSecondary className={classes.avatar} />
                      )}
                    </Box>
                  </Box>
                </div>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
      </Grid>

      <Grid item xs={12}>
        <Grid
          data-testid="user-info-container"
          className={clsx(classes.userContainer)}
          container
          direction="row"
          alignItems="stretch"
        >
          <Grid md={2} sm={3} className={classes.item} item />
          <Grid md={8} sm={7} item />
          <Grid md={2} sm={2} item ref={wrapperRefUserOptions}>
            {userInfoFlag && (
              <div ref={userInfoReference}>
                {userInfo?.users?.[0] ? (
                  <UserOptions
                    setRef={userOptionsReference}
                    closeProfileOptions={() => setUserInfoFlag(false)}
                    name={`${userInfo.users[0].firstName || ''} ${
                      userInfo.users[0].lastName || ''
                    }`}
                    email={userInfo.users[0].email || ''}
                    company={userInfo?.users?.[0]?.primaryPartyDetails?.partyName || ''}
                  />
                ) : (
                  <UserOptions email="Read Only" company="" />
                )}
              </div>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Header;
