// Need to disable this rule for we don't want to reload the component
/* eslint-disable no-param-reassign */
import { LoginState, LoginActionTypes, loginActionNames } from './types';

const initialState: LoginState = {
  isLoginSuccess: false,
  isLoginPending: false,
  loginError: undefined,
  moduleCode: 'CM',
  authInfo: null,
  isRefreshTokenLoading: false,
  environmentVariables: {
    accessRequestsDateFilterFlag: false,
    appDynamicsKey: 'AD-AAB-ACY-UWW',
    assetManagementFilters: false,
    announcementContent: '',
    announcementEndDate: '',
    announcementStartDate: '',
    antiForgeryTtl: 30,
    assetManagementFlag: false,
    assetsV2: '',
    subscriptionsV3: '',
    authURI: '',
    blocksComponents: true,
    countriesApiFlag: true,
    countriesV1: '',
    cuobTestFlag: true,
    customerAssetWorkflowV1: '',
    customerMasterURI: '',
    currentEnvironment: '',
    dcnDisassociationFlag: true,
    deploymentDateTime: '',
    deploymentDuration: 2,
    eCommerceV1: '',
    entitlementRoleManagementV1: '',
    entitlementsV1: '',
    hoursBeforeMaintanenceBannerDisplay: 24,
    leniency: 2,
    logoutURI: '',
    multipleDCNRecommendation: false,
    notificationsFlag: false,
    autoApproveFlag: false,
    proofCodeTtl: 25,
    redirectURI: '',
    refreshSession: true,
    resentInvitationDetailsFlag: false,
    restrictedAccountsFlag: false,
    testAdminAccessRequestAndManageusersFlag: false,
    standardTest: false,
    applicationUserManagementFM3Flag: false,
    ownershipRequestsFlag: false,
    assetRecommendationsFlag: false,
    adminsFlag: false,
    serialNumberValidationFlag: false,
    tokenURI: '',
    universalCustomers: '',
    userIdentityManagementV1: '',
    userIdentityV1: '',
    userManagementV1: '',
    userOnboardingApps: { IN: ['PCC', 'FM3'] },
    dealerDetailsV1: '',
    testAdminUserOnboardingApps: { IN: ['PCC', 'FM3'] },
    applicationUserManagementFM3Apps: { IN: ['FM3'], AM: ['FM3'], AR: ['FM3'] },
    userOnboardingV1: '',
    userPreferencesV2: '',
    manufacturerMetadataV1: '',
    assetMetadataV1: '',
    instanceManagementV2: '',
    assetsDCDCNFlag: false,
    customersConsolidateFlag: true,
    AMTChangesOwnershipFlag: false,
    catFiAssociationFlag: true,
  },
  primaryPartyNumber: undefined,
  isPermissionFetching: false,
  isIntegratedDealer: false,
  integratedDealerCodes: [],
  legalAgreementTimestamp: null,
  legalAgreementVersion: null,
  navigationPath: '/my-customers',
  customSettings: {
    filterTags: true,
    defaultDealerCode: false,
    defaultDealerUserCode: false,
    customerData: '',
  },
};

const loginReducer = (state: LoginState = initialState, action: LoginActionTypes): LoginState => {
  switch (action.type) {
    case loginActionNames.SET_LOGIN_ERROR: {
      return {
        ...state,
        loginError: action.payload,
      };
    }
    case loginActionNames.SET_MODULE_CODE: {
      return {
        ...state,
        moduleCode: action.payload,
      };
    }
    case loginActionNames.SET_CLIENT: {
      return {
        ...state,
        authInfo: action.payload,
      };
    }
    case loginActionNames.SET_ENV_VARIABLES: {
      return {
        ...state,
        environmentVariables: action.payload,
      };
    }
    case loginActionNames.SET_PRIMARY_PARTY_NUMBER: {
      state.primaryPartyNumber = action.payload;
      return state;
    }
    case loginActionNames.SET_PERMISSIONS: {
      return {
        ...state,
        permissions: action.payload,
      };
    }
    case loginActionNames.SET_PERMISSIONS_FETCHING: {
      return {
        ...state,
        isPermissionFetching: action.payload,
      };
    }
    case loginActionNames.SET_ROLES: {
      return {
        ...state,
        roles: action.payload,
      };
    }
    case loginActionNames.SET_PERMISSIONS_RESPONSE: {
      return {
        ...state,
        permissionsResponse: action.payload,
      };
    }
    case loginActionNames.SET_PARTIES_RESPONSE: {
      return {
        ...state,
        partiesResponse: action.payload,
      };
    }
    case loginActionNames.SET_USER_INFO_RESPONSE: {
      return {
        ...state,
        userInfoResponse: action.payload,
      };
    }
    case loginActionNames.SET_PERMISSIONS_UPDATE_FLAG: {
      return {
        ...state,
        permissionsUpdateFlag: action.payload,
      };
    }
    case loginActionNames.SET_UPDATE_TOKEN_FLAG: {
      return {
        ...state,
        updateToken: action.payload,
      };
    }
    case loginActionNames.SET_INTEGRATED_DEALER_FLG: {
      return {
        ...state,
        isIntegratedDealer: action.payload,
      };
    }
    case loginActionNames.SET_INTEGRATED_DEALER_CODES: {
      return {
        ...state,
        integratedDealerCodes: action.payload,
      };
    }
    case loginActionNames.SET_CUSTOM_PREFERENCES: {
      return {
        ...state,
        legalAgreementTimestamp: action.payload.legalAgreementTimestamp,
        legalAgreementVersion: action.payload.legalAgreementVersion,
        customSettings: action.payload.customSettings,
      };
    }
    case loginActionNames.SET_CUSTOM_SETTINGS: {
      return { ...state, customSettings: action.payload };
    }
    case loginActionNames.SET_NAVIGATION_PATH: {
      return {
        ...state,
        navigationPath: action.payload,
      };
    }
    case loginActionNames.SET_LOGIN_TIME: {
      return {
        ...state,
        loginTime: action.payload,
      };
    }
    case loginActionNames.REFRESH_TOKEN_LOADING: {
      return {
        ...state,
        isRefreshTokenLoading: action.payload,
      };
    }
    default:
      return state;
  }
};

export default loginReducer;
